import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'
import { AttachmentAnalysisStatus } from '@constants'

export interface IAttachmentAnalysisData {
  type: 'INVOICE' | 'RECEIPT' | 'UNKNOWN_EXPENSE' | 'NOT_EXPENSE'
}

export enum IAttachmentAnalysisValueType {
  CONTACT_NAME = 'CONTACT_NAME',
  CURRENCY_CODE = 'CURRENCY_CODE',
  INVOICE_DATE = 'INVOICE_DATE',
  INVOICE_DUE_DATE = 'INVOICE_DUE_DATE',
  RECEIPT_DATE = 'RECEIPT_DATE',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT'
}

export interface IAttachmentAnalysisValue {
  type: IAttachmentAnalysisValueType
  value?: string
}

export interface IAttachmentAnalysis {
  values: IAttachmentAnalysisValue[]
  analysis_data?: IAttachmentAnalysisData
}

export interface IFile {
  id: number
  name: string
  type: string
  file: string
  blurhash?: string
  blurhash_w?: number
  blurhash_h?: number
  folder_id?: number
  created_at: string
  is_deletable?: boolean
  analysis_results: IAttachmentAnalysis[]
  analysis_status: AttachmentAnalysisStatus
}

interface FileListProps {
  businessId: string
}

interface FileListForDocumentProps {
  businessId: string
  documentId: number
}

interface FileInstanceProps {
  fileId: number
  businessId: string
}

export const fetchFiles: QueryFunction<FileListProps, PaginatedResponse<IFile>> = async (
  { businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/files/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const fetchFilesForDocument: QueryFunction<
  FileListForDocumentProps,
  PaginatedResponse<IFile>
> = async ({ businessId, documentId }, params = null) => {
  const url = `/v1/business/${businessId}/document/${documentId}/files/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const fetchFile: QueryFunction<FileInstanceProps, IFile> = async (
  { fileId, businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/files/${fileId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updateFile: MutationFunction<FileInstanceProps, IFile> = async (
  { fileId, businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/files/${fileId}/`
  const { status, data } = await network.httpClient.request({
    url,
    data: requestData,
    method: 'PATCH',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteFile: QueryFunction<FileInstanceProps, void> = async (
  { fileId, businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/files/${fileId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE',
    params
  })
}

export interface UploadFileParams {
  businessId: string
  fileName: string
  folderId?: number
}

export const uploadFile = async (
  file: File,
  { businessId, fileName, folderId }: UploadFileParams
): Promise<IFile> => {
  const url = `/v1/business/${businessId}/file_upload/`
  const { status, data } = await network.httpClient.post(
    url,
    {
      name: fileName,
      folder_id: folderId,
      type: file.type,
      file: file
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      validateStatus: () => true,
      timeout: 60000
    }
  )
  return handleReturn({ status, data })
}

interface DownloadFileProps {
  fileId: number
  businessId: string
}

export const downloadFile: QueryFunction<DownloadFileProps, Blob> = async (
  { fileId, businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/files/${fileId}/download/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params,
    responseType: 'blob'
  })
  return data
}
