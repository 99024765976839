import { useQueryParam } from '@hooks'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { isValidElement, useEffect } from 'react'
import styled from 'styled-components'

interface TabsProps {
  queryKey?: string
  children: Array<React.ReactElement<TabProps>> | React.ReactElement<TabProps>
}

export const Tabs: React.FC<TabsProps> = ({ queryKey = 'tab', children: _children }) => {
  const [active, setActive] = useQueryParam<string>(queryKey)

  const children = (Array.isArray(_children) ? _children : [_children]).filter(
    isValidElement
  ) as Array<React.ReactElement<TabProps>>
  const activeKey = active || children[0]?.key

  // If any of the tabs is active, set the first tab as active
  useEffect(() => {
    if (!children.some(children => children.key === activeKey)) {
      setActive(children[0].key as any)
    }
  }, [activeKey])

  return (
    <StyledTabsWrapper className="tabs-wrapper">
      <StyledTabs className="tabs-header">
        {children.map(({ key, props: { icon, name } }) => (
          <StyledTabButton
            key={`tab-${key}`}
            className={cn({ isSelected: key === activeKey })}
            onClick={() => setActive(key as any)}
          >
            {icon} {name}
          </StyledTabButton>
        ))}
      </StyledTabs>

      <AnimatePresence mode="wait">
        {children.filter(({ key }) => key === activeKey)}
      </AnimatePresence>
    </StyledTabsWrapper>
  )
}

interface TabProps {
  key: string
  name: string
  icon?: React.ReactNode
  children: React.ReactNode
}

export const Tab: React.FC<TabProps> = ({ children }) => {
  return <StyledTab className="tabs-tab">{children}</StyledTab>
}

const BORDER_WIDTH = '2px'

const StyledTabButton = styled.button`
  background: white;
  border: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.defaultHover};
  margin-bottom: -${BORDER_WIDTH};
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
  outline: none !important;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  background: ${({ theme }) => theme.colors.defaultHover};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  &.isSelected {
    border: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.neutralGray};
    border-bottom: ${BORDER_WIDTH} solid white;
    background: ${({ theme }) => theme.colors.neutralWhite};
  }
`

const StyledTabs = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  border-bottom: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.neutralGray};
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
  white-space: nowrap;

  overflow: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledTabsWrapper = styled.div``

const StyledTab = styled(motion.div).attrs({
  initial: { opacity: 0, y: -5 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 5 }
})``
