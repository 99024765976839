import { AnimatedContentLoader, GetStarted, Page } from '@components'
import {
  BusinessContext,
  DateRangeProvider,
  FeatureContextProvider,
  FeatureWrapper,
  PermissionContextProvider,
  useFeature
} from '@context'
import { BankIntegrationOnboardingPage } from '@pages/BankIntegrationOnboardingPage'
import {
  collectFromPages,
  fetchBusiness,
  IBusiness,
  trackBusinessPageVisit,
  triggerBankIntegrationBackgroundFetch,
  useCurrentUser
} from '@query'
import React, { useEffect } from 'react'
import { useCustomerly } from 'react-live-chat-customerly'
import { useQuery, useQueryClient } from 'react-query'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'

import AccountsPage from '@pages/AccountsPage'
import ContactsPage from '@pages/ContactsPage'
import DashboardPage from '@pages/DashboardPage'
import DocumentPage from '@pages/DocumentPage'
import FilesPage from '@pages/FilesPage'
import InvoicingPage from '@pages/InvoicingPage'
import PeriodPage from '@pages/PeriodPage'
import ProductPage from '@pages/ProductPage'
import ReportsPage from '@pages/ReportsPage'
import SettingsPage from '@pages/SettingsPage'
import VatPage from '@pages/VatPage'
import SalaryPage from '@pages/SalaryPage/SalaryPage.tsx'

interface BusinessPageProps {
  baseUrl: string
}

const LAST_BUSINESS_WILDCARD = 'business'

export const BusinessPage: React.FC<BusinessPageProps> = ({ baseUrl }) => {
  const history = useHistory()
  const { businessId } = useParams<{ businessId: string }>()
  const performRedirect = businessId === LAST_BUSINESS_WILDCARD
  const queryClient = useQueryClient()
  const { update: updateCustomerly } = useCustomerly()

  const { data: user } = useCurrentUser()
  const showGetStartedInfo = user?.show_get_started_info

  const { data, isLoading } = useQuery([businessId], () => fetchBusiness({ businessId }), {
    initialData: () => {
      const data = queryClient.getQueryData(['businesses'])
      const businesses = collectFromPages<IBusiness>(data as any)
      return businesses.find(b => b.business_id === businessId)
    },
    retry: 2,
    onError: () => history.push('/'),
    enabled: !performRedirect
  })
  const business = data

  useEffect(() => {
    if (user && user.tos_accepted && business) {
      updateCustomerly({
        user_id: user?.id,
        company: {
          company_id: business.business_id,
          name: business.name
        }
      })
    }
  }, [user, business])

  // Redirect to the business user has accessed last time
  // This is for email links etc.
  useEffect(() => {
    if (performRedirect && user?.id) {
      if (user.last_active_business_id) {
        const path = decodeURIComponent(window.location.pathname)
        history.push(
          path.replace(LAST_BUSINESS_WILDCARD, user?.last_active_business_id) +
            window.location.search
        )
      } else {
        history.push('/')
      }
    }
  }, [performRedirect, user?.id])

  if (data?.name) {
    window.document.title = 'NOCFO | ' + data?.name
  } else {
    window.document.title = 'NOCFO'
  }

  return (
    <BusinessContext.Provider
      value={{
        businessId,
        businessName: business?.name,
        periodId: business?.period_id,
        vatPeriodId: business?.vat_period_id,
        data: business || {}
      }}
    >
      <DateRangeProvider>
        <PermissionContextProvider>
          <FeatureContextProvider>
            <Switch>
              <Route path={`${baseUrl}/bank-integration-flow`}>
                <BankIntegrationOnboardingPage baseUrl={`${baseUrl}/bank-integration-flow`} />
              </Route>

              <Route path={`${baseUrl}/salaries`}>
                <FeatureWrapper feature="feature_salaries">
                  <SalaryPage />
                </FeatureWrapper>
              </Route>

              <Route>
                <VisitTracker businessId={businessId} />
                <Page>
                  <AnimatedContentLoader isLoading={isLoading}>
                    {businessId && (
                      <Switch>
                        <Route exact path={`${baseUrl}`}>
                          <DashboardPage />
                        </Route>

                        <Route path={`${baseUrl}/documents`}>
                          <DocumentPage location={baseUrl} />
                        </Route>

                        <Route path={`${baseUrl}/invoicing`}>
                          <InvoicingPage />
                        </Route>

                        <Route path={`${baseUrl}/products`}>
                          <ProductPage />
                        </Route>

                        <Route path={`${baseUrl}/accounts`}>
                          <AccountsPage />
                        </Route>

                        <Route path={`${baseUrl}/accounting`}>
                          <PeriodPage />
                        </Route>

                        <Route path={`${baseUrl}/vat`}>
                          <VatPage />
                        </Route>

                        <Route path={`${baseUrl}/reporting`}>
                          <ReportsPage />
                        </Route>

                        <Route path={`${baseUrl}/files`}>
                          <FilesPage />
                        </Route>

                        <Route path={`${baseUrl}/settings`}>
                          <SettingsPage />
                        </Route>

                        <Route path={`${baseUrl}/contacts`}>
                          <ContactsPage />
                        </Route>
                      </Switch>
                    )}
                  </AnimatedContentLoader>
                </Page>

                {showGetStartedInfo && <GetStarted />}
              </Route>
            </Switch>
          </FeatureContextProvider>
        </PermissionContextProvider>
      </DateRangeProvider>
    </BusinessContext.Provider>
  )
}

const VisitTracker: React.FC<{ businessId: string }> = ({ businessId }) => {
  const bankIntegrationsEnabled = useFeature('feature_bank_integrations')

  // Business visit tracker
  useQuery(['visit_tracker', businessId], () => trackBusinessPageVisit({ businessId }), {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false
  })

  // Bank integration background fetch
  useQuery(
    ['background_fetch', businessId, bankIntegrationsEnabled],
    () => triggerBankIntegrationBackgroundFetch({ businessId }),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 15, // 15 min
      refetchInterval: 1000 * 60 * 60, // 1 hour
      refetchIntervalInBackground: true,
      retry: false,
      enabled: bankIntegrationsEnabled === true
    }
  )

  return null
}
