import { UserRole } from '@constants'
import { BusinessContext } from '@context'
import { useCurrentUser } from '@query'
import React, { useContext, useMemo } from 'react'

const ROLE_HIERARCHY = {
  [UserRole.READ_ONLY]: 1,
  [UserRole.EDITOR]: 2,
  [UserRole.ADMIN]: 3
}

const PermissionContext = React.createContext<{ role?: UserRole }>({
  role: null
})

export const PermissionContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { businessId } = useContext(BusinessContext)
  const { data } = useCurrentUser()

  const currentRole = useMemo(
    () => data?.userships.find(usership => usership.business_id === businessId),
    [businessId, data?.id]
  )

  return (
    <PermissionContext.Provider value={{ role: currentRole?.role }}>
      {children}
    </PermissionContext.Provider>
  )
}

interface PermissionBoundaryProps {
  requireRole: UserRole
  children: React.ReactNode
}

export const usePermissionBoundary = (requireRole: UserRole): boolean => {
  const { role } = useContext(PermissionContext)
  const targetRank = ROLE_HIERARCHY[requireRole] || 0
  const currentRank = ROLE_HIERARCHY[role] || -1
  return currentRank >= targetRank
}

export const PermissionBoundary: React.FC<PermissionBoundaryProps> = ({
  requireRole,
  children
}) => {
  const grantPermission = usePermissionBoundary(requireRole)
  return grantPermission ? <>{children}</> : null
}
