import React, { useCallback, useEffect, useRef } from 'react'
import { Input, Select, ToggleContainer } from '@root/components'
import { FormProvider, get, useFormContext } from 'react-hook-form'
import {
  INVOICE_REFERENCE_METHODS,
  INVOICE_REPETITION_DAYS,
  INVOICE_REPETITION_MONTHS,
  INVOICE_REPETITION_OPTIONS,
  InvoiceRepetitionOptions
} from '@root/constants'
import { useTranslation } from 'react-i18next'
import { IInvoice } from '@root/query'
import dayjs from 'dayjs'
import styled from 'styled-components'
import InvoiceRepetitionDeliveryMethod from './InvoiceRepetitionDeliveryMethod'
import { theme } from '@root/styles'
import { FeatureWrapper } from '@root/context'

interface Props {
  invoiceReceiver: number
  toggleAutoOpen?: boolean
  isCreditNote: boolean
}

const InvoiceFromMoreSection: React.FC<Props> = ({
  invoiceReceiver,
  toggleAutoOpen = false,
  isCreditNote
}) => {
  const containerRef = useRef(null)
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext<Partial<IInvoice>>()

  const methods = useFormContext()

  const [t] = useTranslation()

  const [repetitionType, repetitionMonth, invoicingDate] = watch([
    'recurrence_rule.rule.frequency',
    'recurrence_rule.rule.by_month',
    'invoicing_date'
  ])

  /**
   * Open the recurrence container if a repetition type is selected.
   * Cannot only use the openByDefault, because loading data from cache would
   * populate the data too late for the openByDefault to work.
   */
  useEffect(() => {
    if (repetitionType === InvoiceRepetitionOptions.NO_SELECTION) {
      return
    }
    containerRef.current.handleContainerOpen(true)
  }, [repetitionType])

  const generateDefaultValueForRecurrenceDate = (invoicingDate: string) => {
    return parseInt(invoicingDate.split('-')[2])
  }

  const defaultValueForRecurrenceDate = generateDefaultValueForRecurrenceDate(invoicingDate)

  const dateValidation = useCallback(date => {
    if (date === '') {
      return true
    }

    const dateObj = dayjs(date, 'YYYY-MM-DD')
    if (!dateObj.isValid()) {
      return t('validation.invalidDate')
    }

    if (dateObj.isBefore(dayjs(new Date()))) {
      return t('validation.dateInFuture')
    }

    return true
  }, [])

  const showMonthSelector = repetitionType === InvoiceRepetitionOptions.YEARLY
  const showDaySelector =
    (repetitionType === InvoiceRepetitionOptions.YEARLY && repetitionMonth) ||
    repetitionType === InvoiceRepetitionOptions.MONTHLY

  const showRest = repetitionType !== InvoiceRepetitionOptions.NO_SELECTION
  const showDelivery = repetitionType !== InvoiceRepetitionOptions.NO_SELECTION

  return (
    <FormProvider {...methods}>
      <ToggleContainer
        title={t('invoicing.form.more')}
        openByDefault={toggleAutoOpen}
        ref={containerRef}
      >
        {/* OTHER */}
        <Input
          label={t('invoicing.form.reference')}
          errors={errors?.reference}
          info={t('invoicing.form.referenceInfo')}
          {...register('reference')}
        />
        <Input
          label={t('invoicing.form.penaltyInterest')}
          type="number"
          info={t('invoicing.form.penaltyInterestInfo')}
          errors={errors?.penalty_interest}
          step="0.1"
          {...register('penalty_interest', { valueAsNumber: true })}
        />

        {/* RECURRENCE */}
        <FeatureWrapper feature={'feature_recurring_invoices'}>
          {!isCreditNote && (
            <RepetitionWrapper>
              {/* RECURRENCE OPTIONS */}
              <Select
                id="invoice-form-repetition-type"
                defaultValue={InvoiceRepetitionOptions.NO_SELECTION}
                label={t('invoicing.form.repetition.label')}
                info={t('invoicing.form.repetition.helperRepetition')}
                errors={get(errors, 'recurrence_rule.rule.frequency')}
                {...register('recurrence_rule.rule.frequency', { valueAsNumber: true })}
              >
                {INVOICE_REPETITION_OPTIONS.map((option, index) => (
                  <option key={`invoice_repetition_option_${index}`} value={option.value}>
                    {t(option.labelKey)}
                  </option>
                ))}
              </Select>

              {showMonthSelector && (
                <Select
                  id="invoice-form-repetition-month"
                  label={t('invoicing.form.repetition.month')}
                  info={t('invoicing.form.repetition.helperMonth')}
                  errors={get(errors, 'recurrence_rule.rule.by_month.0')}
                  required={showMonthSelector}
                  style={{ flex: '1 0 20%', marginRight: `${theme.spacing.xxs}rem` }}
                  {...register('recurrence_rule.rule.by_month.0', {
                    valueAsNumber: true,
                    shouldUnregister: true,
                    required: { value: true, message: t('validation.required') }
                  })}
                >
                  <option hidden={true} value={null} />
                  {INVOICE_REPETITION_MONTHS.map((option, index) => (
                    <option key={`invoice_repetition_month_${index}`} value={option.value}>
                      {t(option.labelKey)}
                    </option>
                  ))}
                </Select>
              )}

              {showDaySelector && (
                <Select
                  id="invoice-form-repetition-day"
                  label={t('invoicing.form.repetition.day')}
                  info={t('invoicing.form.repetition.helperDay')}
                  required={showDaySelector}
                  errors={get(errors, 'recurrence_rule.rule.by_month_day.0')}
                  defaultValue={defaultValueForRecurrenceDate}
                  style={{
                    flex: showMonthSelector ? '1 0 30%' : '1 0 40%',
                    marginRight: `${theme.spacing.xxs}rem`
                  }}
                  {...register('recurrence_rule.rule.by_month_day.0', {
                    valueAsNumber: true,
                    shouldUnregister: true,
                    required: { value: true, message: t('validation.required') }
                  })}
                >
                  {INVOICE_REPETITION_DAYS.map((option, index) => (
                    <option key={`invoice_repetition_day_${index}`} value={option.value}>
                      {option.labelKey ? t(option.labelKey) : option.value}
                    </option>
                  ))}
                </Select>
              )}

              {/* LAST DAY OF REPETITION AND REFERENCE NUMBER */}
              {showRest && (
                <>
                  <Input
                    label={t('invoicing.form.repetition.invoicingEnd')}
                    type="date"
                    info={t('invoicing.form.repetition.invoicingEndInfo')}
                    style={{ flex: '1 0 40%' }}
                    errors={get(errors, 'recurrence_end')}
                    {...register('recurrence_end', {
                      validate: dateValidation,
                      shouldUnregister: true
                    })}
                  />

                  <Select
                    id="invoice-form-reference-methods"
                    label={t('invoicing.form.reference')}
                    info={t('invoicing.form.repetition.referenceInfo')}
                    required={true}
                    errors={get(errors, 'recurrence_reference_method')}
                    {...register('recurrence_reference_method', {
                      shouldUnregister: true,
                      required: { value: true, message: t('validation.required') }
                    })}
                  >
                    <option hidden={true} value={null} />
                    {INVOICE_REFERENCE_METHODS.map((option, index) => (
                      <option
                        key={`invoice_repetition_reference_method_${index}`}
                        value={option.value}
                      >
                        {t(option.labelKey)}
                      </option>
                    ))}
                  </Select>
                </>
              )}

              {/* DELIVERY METHODS */}
              {showDelivery && (
                <InvoiceRepetitionDeliveryMethod
                  invoiceReceiverId={invoiceReceiver || null}
                ></InvoiceRepetitionDeliveryMethod>
              )}
            </RepetitionWrapper>
          )}
        </FeatureWrapper>
      </ToggleContainer>
    </FormProvider>
  )
}

export default InvoiceFromMoreSection

const RepetitionWrapper = styled.div`
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.xl}rem;
`
