import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SendInvoiceFormBase from '../SendInvoiceFormBase'

interface Props {
  invoiceReceiverId: number
}

const InvoiceRepetitionDeliveryMethod: React.FC<Props> = ({ invoiceReceiverId }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledTitle>{t('invoicing.form.repetition.headerSending')}</StyledTitle>
      <SendInvoiceFormBase
        invoiceReceiver={invoiceReceiverId}
        emailContentName={'recurrence_email_content'}
        emailSubjectName={'recurrence_email_subject'}
      ></SendInvoiceFormBase>
    </>
  )
}

export default InvoiceRepetitionDeliveryMethod

const StyledTitle = styled.h3`
  flex: 1 0 100%;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`
