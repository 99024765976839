import { Button, FilterBar, SearchInput, UploadFilesButton } from '@components'
import { UserRole } from '@constants'
import { FeatureWrapper, PermissionBoundary, useBusinessContext } from '@context'
import { useQueryParam, useQueryParams } from '@hooks'
import { createFolder, IFile, uploadFile } from '@query'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCloudUploadAlt, FaFolderPlus } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface Props {
  onSearch: (value: string) => void
  onFilter: (filters: any) => void
}

export const ActionBar: React.FC<Props> = ({ onSearch, onFilter }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [folderId] = useQueryParam<number>('folder', value =>
    value ? parseInt(value, 10) : undefined
  )
  const [, setParams] = useQueryParams()
  const { businessId } = useBusinessContext()

  const { mutate: createBlankFolder, isLoading: isCreatingFolder } = useMutation(
    () =>
      createFolder({ businessId }, { name: t('files.actionBar.defaultName'), parent: folderId }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries([businessId, 'folders'])
        setParams({
          id: data.id,
          idType: 'folder'
        })
      }
    }
  )

  const { mutateAsync: uploadFileMutation, isLoading: isUploading } = useMutation<
    IFile,
    unknown,
    File
  >(file => uploadFile(file, { businessId, fileName: file.name, folderId }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([businessId, 'files'])
    },
    onError: ({ status }) => {
      if (status == 426) {
        toast.error(t('error.upgradeRequired'))
      }
    }
  })

  return (
    <ActionBarWrapper>
      <SearchBarWrapper>
        <SearchInput placeholder={t('files.actionBar.search')} onSearch={onSearch} />
      </SearchBarWrapper>

      <FeatureWrapper feature="feature_unlimited_storage">
        <PermissionBoundary requireRole={UserRole.EDITOR}>
          <ActionButtonWrapper>
            <ActionUploadButton
              icon={<FaCloudUploadAlt />}
              uploadFile={uploadFileMutation}
              showSpinner={isUploading}
              disabled={isUploading}
            >
              {t('files.actionBar.newFile')}
            </ActionUploadButton>
            <ActionButton
              icon={<FaFolderPlus />}
              showSpinner={isCreatingFolder}
              onClick={() => createBlankFolder()}
            >
              {t('files.actionBar.newFolder')}
            </ActionButton>
          </ActionButtonWrapper>
        </PermissionBoundary>
      </FeatureWrapper>

      <div style={{ width: '100%' }}>
        <FilterBar
          onFilter={onFilter}
          filters={[
            {
              id: 'not-used',
              name: t('files.filters.notUsed'),
              filter: { is_used: false }
            }
          ]}
        />
      </div>
    </ActionBarWrapper>
  )
}

const ActionBarWrapper = styled.div`
  background: white;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.md}rem;
  flex-wrap: wrap;
`

const SearchBarWrapper = styled.div`
  flex: 1;
  min-width: 200px;
`

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const ActionButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
`

const ActionUploadButton = styled(UploadFilesButton)`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
`
