import { AnimatedContentLoader, Button, InputProps, MonetaryAmount, SearchInput } from '@components'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DropdownWrapper,
  Header,
  Item,
  ItemColumn,
  ItemContainer,
  StyledInvoiceCopyDropdown
} from './InvoiceCopyDropdown.styled'
import { FaCopy } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { fetchInvoices } from '@root/query'
import { useBusinessContext } from '@root/context'
import { IInvoice } from '@root/query'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { AnimatePresence } from 'framer-motion'
import { createPortal } from 'react-dom'

interface Props extends InputProps {
  copyInvoiceData: (IInvoice: IInvoice) => void
}

export const InvoiceCopyDropdown: React.FC<Props> = ({ copyInvoiceData }) => {
  const { businessId } = useBusinessContext()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [focusIndex, setFocusIndex] = useState(-1)
  const numberOfSearchResults = 10
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))

  const { data, isLoading } = useQuery(
    [businessId, 'invoice_similarity', { searchTerm }],
    () => fetchInvoices({ businessId }, { search: searchTerm, pageSize: numberOfSearchResults }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev,
      enabled: searchTerm.length >= 3
    }
  )

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      offset(10),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${Math.min(500, availableWidth - 48)}px`,
            maxHeight: `${Math.min(6000, availableHeight - 48)}px`
          })
        }
      })
    ]
  })

  useDismiss(context)

  const handleClose = () => {
    setIsOpen(false)
    setFocusIndex(-1)
    setSearchTerm('')
  }

  const onCopy = (result: IInvoice) => {
    copyInvoiceData(result)
    handleClose()
  }

  const onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown': {
        if (focusIndex === data?.results.length - 1) {
          setFocusIndex(0)
          break
        }
        setFocusIndex(Math.min(focusIndex + 1, data?.results.length - 1))
        break
      }

      case 'ArrowUp': {
        if (focusIndex === 0) {
          setFocusIndex(data?.results.length - 1)
          break
        }
        setFocusIndex(Math.max(focusIndex - 1, 0))
        break
      }

      case 'Enter': {
        const result = data?.results[focusIndex]
        if (result) {
          onCopy(result)
        }
        setFocusIndex(-1)
        setIsOpen(false)
        e.stopPropagation()
        e.preventDefault()
        break
      }

      case 'Escape': {
        handleClose()
        break
      }
    }
  }

  return (
    <DropdownWrapper>
      <Button
        type="button"
        icon={<FaCopy />}
        onClick={() => setIsOpen(!isOpen)}
        ref={reference}
      ></Button>
      {createPortal(
        <AnimatePresence>
          {isOpen && (
            <StyledInvoiceCopyDropdown
              ref={floating}
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy
              }}
            >
              <Header>{t('invoicing.form.copyInvoiceHeaderDetail')}</Header>
              <SearchInput
                placeholder={t('invoicing.form.searchHelperText')}
                onSearch={searchTerm => setSearchTerm(searchTerm)}
                onKeyDown={onKeyDown}
              />
              <ItemContainer>
                <AnimatedContentLoader
                  isLoading={isLoading}
                  isEmpty={data?.results.length === 0}
                  isEmptyDescription={t('invoicing.form.noSearchResults')}
                >
                  {data?.results.map((result, i) => (
                    <Item
                      key={result.id}
                      className={focusIndex === i && 'keyboard-focused'}
                      onTouchStart={() => {
                        onCopy(result)
                      }}
                      onMouseDown={() => {
                        onCopy(result)
                      }}
                      onKeyDown={onKeyDown}
                    >
                      {/* If no id found, it is a draft */}
                      <ItemColumn className="id">
                        {result.invoice_number ? (
                          <code>{result.invoice_number}</code>
                        ) : (
                          t('document.draft')
                        )}
                      </ItemColumn>
                      <ItemColumn className="name">{result.receiver_info.name}</ItemColumn>
                      <ItemColumn className="balance">
                        <MonetaryAmount
                          value={result?.total_vat_amount + result?.total_amount}
                        ></MonetaryAmount>
                      </ItemColumn>
                    </Item>
                  ))}
                </AnimatedContentLoader>
              </ItemContainer>
            </StyledInvoiceCopyDropdown>
          )}
        </AnimatePresence>,
        rootRef.current
      )}
    </DropdownWrapper>
  )
}
