import { Dropdown, Input, InputProps } from '@components'
import { useBusinessContext, useFeature } from '@context'
import { useDebouncedValue } from '@hooks'
import { fetchSimilarDocuments, IDocument } from '@query'
import { formatCurrency } from '@utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  DropdownWrapper,
  Header,
  HeaderDetail,
  HeaderIcon,
  Item,
  ItemColumn
} from './DocumentCopyDropdown.styled'
import styled from 'styled-components'

interface Props extends InputProps {
  label: string
  value: string
  onChange: (any) => void
  excludeIds?: number[]
  onCopy: (document: IDocument) => void
  disableSearch?: boolean
  importModelId?: number
}

export const DocumentCopyDropdown: React.FC<Props> = React.forwardRef(
  (
    {
      label,
      onChange,
      onBlur,
      onCopy: _onCopy,
      excludeIds,
      importModelId,
      disableSearch = false,
      ...rest
    },
    ref
  ) => {
    const { businessId } = useBusinessContext()
    const [isOpen, setIsOpen] = useState(false)
    const [focusIndex, setFocusIndex] = useState(-1)
    const debouncedSearchTerm = useDebouncedValue<string>(rest.value || '', 300)
    const { t } = useTranslation()
    const anchorRef = React.useRef()
    const hasCopyFeature = useFeature('feature_document_copy')
    const { data } = useQuery(
      [businessId, 'document_similarity', { debouncedSearchTerm, importModelId }],
      () => fetchSimilarDocuments({ businessId, search: debouncedSearchTerm, importModelId }),
      {
        enabled: debouncedSearchTerm.length >= 3 && !disableSearch,
        keepPreviousData: debouncedSearchTerm.length >= 3
      }
    )
    const results = data
      ? data.filter(result => (excludeIds ? excludeIds.indexOf(result.id) == -1 : true))
      : []

    const onCopy = (document: IDocument) => {
      if (hasCopyFeature) _onCopy(document)
    }

    const onKeyDown = e => {
      switch (e.key) {
        case 'ArrowDown': {
          setFocusIndex(Math.min(focusIndex + 1, results.length - 1))
          break
        }

        case 'ArrowUp': {
          setFocusIndex(Math.max(focusIndex - 1, 0))
          break
        }

        case 'Enter': {
          const result = results[focusIndex]
          if (result) {
            onChange(result.description)
            onCopy(result)
          }
          setFocusIndex(-1)
          setIsOpen(false)
          e.stopPropagation()
          e.preventDefault()
          break
        }

        case 'Escape': {
          setFocusIndex(-1)
          setIsOpen(false)
          break
        }
      }
    }

    return (
      <DropdownWrapper ref={anchorRef}>
        <Input
          data-test="document-form-description-input"
          ref={ref}
          autoComplete="off"
          label={label}
          onKeyDown={hasCopyFeature && onKeyDown}
          onChange={e => {
            setIsOpen(true)
            onChange(e.currentTarget.value)
          }}
          onBlur={e => {
            setIsOpen(false)
            onBlur?.(e)
          }}
          {...rest}
        />

        {isOpen && results.length > 0 && (
          <Dropdown anchorRef={anchorRef}>
            <Header>
              {t('document.copyHeader')}
              <HeaderIcon />
            </Header>
            <HeaderDetail>{t('document.copyHeaderDetail')}</HeaderDetail>

            <ItemWrapper>
              {results.map((result, i) => (
                <Item
                  key={result.id}
                  className={focusIndex === i && 'keyboard-focused'}
                  onTouchStart={() => {
                    onCopy(result)
                    onChange(result.description)
                  }}
                  onMouseDown={() => {
                    onCopy(result)
                    onChange(result.description)
                  }}
                >
                  <ItemColumn className="number">
                    <code>#{result.number}</code>
                  </ItemColumn>
                  <ItemColumn className="description">{result.description}</ItemColumn>
                  <ItemColumn className="balance">
                    <code>{formatCurrency(result.balance)}</code>
                  </ItemColumn>
                </Item>
              ))}
              {!hasCopyFeature && (
                <DimmedOverlay>
                  <span>{t('teaser.documentCopy')}</span>
                </DimmedOverlay>
              )}
            </ItemWrapper>
          </Dropdown>
        )}
      </DropdownWrapper>
    )
  }
)

const ItemWrapper = styled.div`
  position: relative;
  min-height: 100px;
`

const DimmedOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1.5px);
  background-color: #ffffff77;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.metalGray};
    background: ${({ theme }) => theme.colors.neutralWhite};
    padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.xxs}rem;
    border-radius: 1rem;
  }
`
