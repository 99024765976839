import { useCallback, useEffect, useState } from 'react'

const getLocalStorageValue = (key, initialValue) => {
  try {
    const item = window.localStorage.getItem(key)
    return JSON.parse(item) || initialValue
  } catch (error) {
    console.log(error)
    return initialValue
  }
}

const setLocalStorageValue = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.log(error)
  }
}

export const useLocalStorage = <T,>(key: string, initialValue: T): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState(() => getLocalStorageValue(key, initialValue))

  const setValue = useCallback(
    value => {
      setLocalStorageValue(key, value)
      setStoredValue(value)
    },
    [key]
  )

  useEffect(() => {
    setStoredValue(getLocalStorageValue(key, initialValue))
  }, [key, JSON.stringify(initialValue)])

  return [storedValue, setValue]
}
