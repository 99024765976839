import { Button, FilterBar, SearchInput, TextTooltip } from '@components'
import { UserRole } from '@constants'
import { PermissionBoundary } from '@context'
import { useQueryParam } from '@hooks'
import { useScreen } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaBars, FaPlus, FaTable } from 'react-icons/fa'
import styled from 'styled-components'
import { AccountFormModal } from './AccountFormModal'

interface IFilter {
  id: string
  name: string
  filter: any
}

interface Props {
  onSearch: (string) => void
  onFilter: (string) => void
  filters: IFilter[]
}

export const AccountActionBar: React.FC<Props> = ({ onSearch, onFilter, filters }) => {
  const [t] = useTranslation()
  const [editMode, setEditMode] = useQueryParam<string>('editMode')
  const { isMobile } = useScreen()

  return (
    <StyledActionBar>
      <Row>
        <SearchInput placeholder={t('accounts.actionBar.search')} onSearch={onSearch} />

        <PermissionBoundary requireRole={UserRole.EDITOR}>
          <AccountFormModal>
            <Button icon={<FaPlus />} style={{ marginLeft: '0.5rem' }}>
              {t('accounts.actionBar.newAccount')}
            </Button>
          </AccountFormModal>
        </PermissionBoundary>
      </Row>

      <Row>
        <FilterBar filters={filters} onFilter={onFilter} />

        {!isMobile && (
          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <TextTooltip tooltip={t('accounts.actionBar.editModeToggleTooltip')}>
              <AnimatePresence>
                {editMode === 'tabular' && (
                  <EditModeIconWrapper>
                    <FaBars onClick={() => setEditMode(undefined)} />
                  </EditModeIconWrapper>
                )}
                {editMode !== 'tabular' && (
                  <EditModeIconWrapper>
                    <FaTable onClick={() => setEditMode('tabular')} />
                  </EditModeIconWrapper>
                )}
              </AnimatePresence>
            </TextTooltip>
          </PermissionBoundary>
        )}
      </Row>
    </StyledActionBar>
  )
}

const EditModeIconWrapper = styled(motion.div).attrs({
  whileHover: { scale: 1.2 },
  whileTap: { scale: 0.9 }
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.iconGray};
`

const StyledActionBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;

  & > * {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .badge {
    cursor: pointer;
    margin-right: 0.4rem;
  }
`
