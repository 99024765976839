import {
  AnimatedContentLoader,
  DoubleColumnLayout,
  DoubleColumnLayoutColumn,
  SingleColumnLayout,
  TeaserView
} from '@components'
import { useBusinessContext, useFeature } from '@context'
import { useQueryParam } from '@hooks'
import { collectFromPages, fetchCombinedInvoices } from '@query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { InvoiceDetails } from './InvoiceDetails'
import { InvoiceList } from './InvoiceList'
import { InvoicingActionBar } from './InvoicingActionBar'
import { InvoicingOnboarding } from './InvoicingOnboarding'
import { PurchaseInvoiceDetails } from './PurchaseInvoiceDetails'

export const InvoicingPage: React.FC = () => {
  const [t] = useTranslation()
  const showTeaserView =
    useFeature('feature_invoicing') === false && !useFeature('feature_show_received_einvoices')
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<any>()
  const {
    businessId,
    data: {
      onboarding_updated_invoicing_settings,
      onboarding_created_invoicing_contact,
      onboarding_created_invoicing_product
    }
  } = useBusinessContext()
  const [selectedId, setSelectedId] = useQueryParam<number>('id', value => parseInt(value, 10))
  const [selectedType] = useQueryParam<number>('type')
  const showOnboarding = !(
    onboarding_updated_invoicing_settings &&
    onboarding_created_invoicing_contact &&
    onboarding_created_invoicing_product
  )

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'combined_invoices', { search, filter }],
    ({ pageParam }) =>
      fetchCombinedInvoices({ businessId }, { page: pageParam || 1, search, ...filter }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev,
      enabled: !showTeaserView
    }
  )
  const combinedInvoices = collectFromPages(data)

  const onClose = () => {
    setSelectedId(undefined)
  }

  if (showTeaserView) {
    return (
      <SingleColumnLayout header={t('invoicing.mainTitle')}>
        <TeaserView
          header={t('teaser.invoicing.header')}
          subHeader={t('teaser.invoicing.subHeader')}
          features={[
            t('teaser.feature.2'),
            t('teaser.feature.1'),
            t('teaser.feature.3'),
            t('teaser.feature.4'),
            t('teaser.feature.5')
          ]}
          maxWidth="800px"
        />
      </SingleColumnLayout>
    )
  }

  if (showOnboarding) {
    return (
      <SingleColumnLayout header={t('invoicing.mainTitle')}>
        <InvoicingOnboarding />
      </SingleColumnLayout>
    )
  }

  return (
    <DoubleColumnLayout
      header={t('invoicing.mainTitle')}
      isRightVisible={Boolean(selectedId)}
      onRightClose={() => onClose()}
    >
      <DoubleColumnLayoutColumn>
        <PageContentWrapper>
          <InvoicingActionBar
            onSearch={value => setSearch(value)}
            onFilter={value => setFilter(value)}
          />
          <PeriodListWrapper>
            <AnimatedContentLoader
              isLoading={isLoading}
              isEmpty={combinedInvoices.length === 0}
              isEmptyDescription={t('invoicing.emptyDescription')}
            >
              <InvoiceList
                combinedInvoices={combinedInvoices}
                onScrollBottom={() => hasNextPage && fetchNextPage()}
              />
            </AnimatedContentLoader>
          </PeriodListWrapper>
        </PageContentWrapper>
      </DoubleColumnLayoutColumn>
      <DoubleColumnLayoutColumn innerKey={selectedId} isRight={true}>
        {selectedType === 'sales' && (
          <InvoiceDetails invoiceId={selectedId} onClose={() => onClose()} />
        )}
        {selectedType === 'purchase' && (
          <PurchaseInvoiceDetails invoiceId={selectedId} onClose={() => onClose()} />
        )}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const PageContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PeriodListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
`
