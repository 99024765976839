import { useBusinessContext } from '@context'
import { fetchFile } from '@query'
import { callAll } from '@utils'
import { motion } from 'framer-motion'
import React, {
  cloneElement,
  JSXElementConstructor,
  ReactElement,
  useMemo,
  useRef,
  useState
} from 'react'
import Lightbox from 'react-18-image-lightbox'
import { createPortal } from 'react-dom'
import { FaSpinner, FaTimes } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'

interface Props {
  children: ReactElement<any, string | JSXElementConstructor<any>>
  fileId: number
}

const ACTION_BAR_HEIGHT = '4rem'
const MAX_MODAL_HEIGHT = 1400
const MAX_MODAL_WIDTH = 1200

export const FilePreview: React.FC<Props> = ({ children, fileId }) => {
  const { businessId } = useBusinessContext()
  const rootRef = useRef<HTMLElement>(document.getElementById('modal-root'))
  const [isOpen, setOpen] = useState(false)

  const { data: file, isLoading } = useQuery([businessId, 'files', fileId], () =>
    fetchFile({ businessId, fileId })
  )

  const modalElement = useMemo(() => {
    const isImage = file?.type.toLowerCase().indexOf('image') !== -1
    const isPdf = file?.type.toLowerCase() === 'application/pdf'

    if (isLoading) {
      return (
        <Backdrop onClick={() => setOpen(false)}>
          <FaSpinner className="spin" />
        </Backdrop>
      )
    }

    if (isImage) {
      return <Lightbox mainSrc={file?.file} onCloseRequest={() => setOpen(false)} />
    }

    if (isPdf) {
      return (
        <Backdrop onClick={() => setOpen(false)}>
          <ActionBar>
            <CloseButton>
              <FaTimes />
            </CloseButton>
          </ActionBar>
          <StyledEmbed src={file?.file} />
        </Backdrop>
      )
    }
  }, [isLoading, file?.type])

  return (
    <>
      {cloneElement(children, {
        onClick: callAll(() => {
          setOpen(true)
        }, children.props.onClick)
      })}

      {isOpen && createPortal(modalElement, rootRef.current)}
    </>
  )
}

const StyledEmbed = styled.iframe`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${MAX_MODAL_WIDTH}px;
  height: ${MAX_MODAL_HEIGHT}px;
  max-width: calc(100vw - ${ACTION_BAR_HEIGHT} - ${ACTION_BAR_HEIGHT});
  max-height: calc(100vh - ${ACTION_BAR_HEIGHT} - ${ACTION_BAR_HEIGHT});
  outline: none;
  border-radius: 1rem;
  overflow: hidden;
  position: absolute;
  border: none;
`

const Backdrop = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backdrop};

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.colors.neutralGray};
    width: 2rem;
    height: 2rem;
  }
`

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  height: ${ACTION_BAR_HEIGHT};
  z-index: 10;
`

const CloseButton = styled.div`
  height: ${ACTION_BAR_HEIGHT};
  width: ${ACTION_BAR_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralBlack}44;
  }

  svg {
    font-size: 2rem;
    color: white;
  }
`
