import { Button, FilterBar, SearchInput, UploadFilesButton } from '@components'
import { UserRole } from '@constants'
import { FeatureWrapper, PermissionBoundary, useDateRangeContext } from '@context'
import { IDocument } from '@query'
import { formatDate, useScreen } from '@utils'
import cn from 'classnames'
import dayjs from 'dayjs'
import React, { useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { FaFileUpload, FaPlus } from 'react-icons/fa'
import styled from 'styled-components'
import { DocumentFormModal } from './DocumentForm'

interface Props {
  documents: IDocument[]
  onSearch: (string) => void
  onFilter: (any) => void
  uploadFile: (File) => Promise<any>
}

export const DocumentActionBar: React.FC<Props> = ({
  documents,
  onSearch,
  onFilter,
  uploadFile
}) => {
  const [t] = useTranslation()
  const buttonRef = useRef(null)
  const [showCreate, setShowCreate] = useState(false)
  const { isMobile } = useScreen()
  const { isActive, selectedRange, reset: resetDateRange } = useDateRangeContext()
  const selectedRangeLabel = useMemo(() => {
    return selectedRange?.key === 'custom'
      ? `${formatDate(selectedRange?.date_from)} - ${formatDate(selectedRange?.date_to)}`
      : t('components.dateRangeFilter.' + selectedRange?.key)
  }, [t, selectedRange])

  useHotkeys('n', e => {
    buttonRef.current?.click()
    e.preventDefault()
  })

  const initialDate = documents[0]?.date || dayjs().format('YYYY-MM-DD')

  const filterOptions = React.useMemo(
    () => [
      {
        id: 'is_sales',
        name: t('document.filter.drafts'),
        filter: { is_draft: true }
      },
      {
        id: 'is_flagged',
        name: t('document.filter.flagged'),
        filter: { is_flagged: true }
      },
      {
        id: 'missing_attachment',
        name: t('document.filter.missingAttachment'),
        filter: { has_attachments: false }
      }
    ],
    []
  )

  return (
    <>
      <StyledSearchWrapper className={cn({ isLast: !isActive })}>
        <Row>
          <SearchInput placeholder={t('document.searchPlaceholder')} onSearch={onSearch} />
          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <Button
              data-test="new-document-button"
              ref={buttonRef}
              type="button"
              icon={<FaPlus />}
              showSpinner={false}
              style={{ marginLeft: '0.5rem', height: '100%' }}
              onClick={() => setShowCreate(true)}
            >
              {t('document.addNew')}
            </Button>

            <DocumentFormModal
              isVisible={showCreate}
              onClose={() => setShowCreate(false)}
              defaults={{ date: initialDate }}
              enableCache={true}
            />
          </PermissionBoundary>
        </Row>

        <Row>
          <FilterBar filters={filterOptions} onFilter={onFilter} />
          <FeatureWrapper feature="feature_csv_import">
            {!isMobile && (
              <PermissionBoundary requireRole={UserRole.EDITOR}>
                <FileImportButtonWrapper
                  disableFullAlert={true}
                  icon={<FaFileUpload />}
                  fileTypes={['.csv']}
                  uploadFile={uploadFile}
                >
                  {t('document.csvImport')}
                </FileImportButtonWrapper>
              </PermissionBoundary>
            )}
          </FeatureWrapper>
        </Row>
      </StyledSearchWrapper>
      {isActive && (
        <FilterInfo>
          <span className="label">
            {t('document.documentsShown', { timePeriod: selectedRangeLabel })}
          </span>
          <a href="#" onClick={() => resetDateRange()} style={{ fontWeight: 'normal' }}>
            {t('document.showAll')}
          </a>
        </FilterInfo>
      )}
    </>
  )
}

const FilterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.neutralWhite}77;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: 1rem;

  .label {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xxs}rem;

    svg {
      margin-top: -2px;
    }
  }
`

const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background: white;
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  &.isLast {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-bottom: 1rem;
  }

  cursor: pointer;

  & > * {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .badge {
    cursor: pointer;
    margin-right: 0.4rem;
  }
`

const FileImportButtonWrapper = styled(UploadFilesButton)`
  display: flex;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray} !important;
  padding: 0;
  background: none;

  & > * {
    align-self: center;
  }
`
