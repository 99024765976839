import { Big, BigSource } from 'big.js'

interface PrecisionOptions {
  maximumFractionDigits?: number
  minimumFractionDigits?: number
}

export const formatCurrency = (
  value: number,
  precision?: PrecisionOptions,
  currency: string = 'EUR'
): string => {
  if (value === null) return ''

  return new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency,
    ...(precision || {})
  }).format(value)
}

export const nbsp = '\u00A0' // non-breaking space TODO create a file for these type of special characters

type EuroFormatOpts = {
  withEuroSymbol?: boolean
  avoidDecimals?: boolean
  withSpacing?: boolean
  inCents?: boolean
}

/**
 * Formats money using 2 decimal places. Returns "-" for invalid sums.
 *
 * @return for example `10.46`
 */
export function formatMoney(
  value?: BigSource | null,
  avoidDecimals = false,
  inCents = true
): string {
  if (value === undefined || value === null || (typeof value === 'number' && isNaN(value))) {
    return '-'
  }

  const rounded = Big(value)
    .div(inCents ? 100 : 1)
    .round(2, 0)

  const decimals = avoidDecimals && Number.isInteger(rounded.toNumber()) ? 0 : 2

  return rounded.toFixed(decimals)
}

export function eurToCents(cents: BigSource): number {
  return Big(cents).div(100).toNumber()
}

export function formatEurosWithOpts(value?: BigSource | null, opts?: EuroFormatOpts): string {
  const {
    withEuroSymbol = true,
    avoidDecimals = false,
    withSpacing = true,
    inCents = true
  } = opts || {}

  if (value === undefined || value === null || (typeof value === 'number' && isNaN(value))) {
    return '-'
  }

  const numValue = formatMoney(value, avoidDecimals, inCents)
  const formattedValue = numValue.replace(/[.]/, ',')
  const spacedValue = withSpacing
    ? formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    : formattedValue

  return withEuroSymbol ? `${spacedValue}${nbsp}€` : `${spacedValue}`
}
