import { Button, SearchInput } from '@components'
import { UserRole } from '@constants'
import { PermissionBoundary } from '@context'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import styled from 'styled-components'
import { ProductFormModal } from './ProductFormModal'

interface Props {
  onSearch: (value: string) => void
}

export const ProductActionBar: React.FC<Props> = ({ onSearch }) => {
  const [t] = useTranslation()
  return (
    <StyledActionBar>
      <SearchInput placeholder={t('products.actionBar.search')} onSearch={onSearch} />

      <PermissionBoundary requireRole={UserRole.EDITOR}>
        <ProductFormModal>
          <Button icon={<FaPlus />}>{t('products.actionBar.addNew')}</Button>
        </ProductFormModal>
      </PermissionBoundary>
    </StyledActionBar>
  )
}

const StyledActionBar = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  justify-content: space-between;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;

  & > * {
    margin-bottom: 0;
  }

  button {
    margin-left: ${({ theme }) => theme.spacing.md}rem;
  }
`
