import { formatCurrency } from '@utils'
import cn from 'classnames'
import React from 'react'
import styled from 'styled-components'

interface Props {
  value: number
  showColor?: boolean
}

export const MonetaryAmount: React.FC<Props> = ({ value, showColor = false }) => {
  const isPositive = value > 0 && showColor
  const isNegative = value < 0 && showColor
  const isNeutral = value === 0 && showColor

  return (
    <StyledMonetaryAmount className={cn({ isPositive, isNegative, isNeutral })}>
      {formatCurrency(value)}
    </StyledMonetaryAmount>
  )
}

const StyledMonetaryAmount = styled.span`
  font-variant-numeric: tabular-nums;

  &.isPositive {
    color: ${({ theme }) => theme.colors.nocfoGreen};
  }

  &.isNegative {
    color: ${({ theme }) => theme.colors.nocfoRed};
  }

  &.isNeutral {
    color: ${({ theme }) => theme.colors.metalGray};
  }
`
