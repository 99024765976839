import { Input, Select, Switch, TextArea } from '@components'
import { ContactType, CONTACT_TYPE_OPTIONS, INVOICING_LANGUAGE_OPTIONS } from '@constants'
import { useBusinessContext } from '@context'
import { createContact, IContact, updateContact } from '@query'
import { setAPIErrors } from '@utils'
import { motion } from 'framer-motion'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import BusinessSearchDropdown from './BusinessSearchDropdown'
import {
  IBasicBusinessInfoFromPrh,
  IPrhSearchResults,
  fetchPrhSearch
} from '@query/integrations/prh.ts'
import BusinessIdDropdown from './BusinessIdDropdown'
interface Props {
  contact?: IContact
  onClose?: () => void
  onCreate?: (data: Partial<IContact>) => void
}

export const ContactForm: React.FC<Props> = ({ contact, onClose, onCreate }) => {
  const isEdit = Boolean(contact)
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [t] = useTranslation()
  const methods = useForm<Partial<IContact>>({
    defaultValues: { ...(contact || {}) }
  })

  const {
    control,
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors }
  } = methods

  const { mutateAsync } = useMutation<unknown, unknown, Partial<IContact>>(
    data =>
      isEdit
        ? updateContact({ businessId, contactId: contact.id }, data)
        : createContact({ businessId }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'contacts'])
        onClose && onClose()
      },
      onError: ({ data }) => {
        setAPIErrors(data, setError)
      }
    }
  )

  const [selectedType, isInvoicingEnabled] = watch(['type', 'is_invoicing_enabled'])

  const removeUnusedFields = (
    data: Partial<IContact>,
    isInvoicingEnabled: boolean
  ): Partial<IContact> => {
    if (isInvoicingEnabled) return data

    const elementsToRemove = [
      'invoicing_email',
      'invoicing_street',
      'invoicing_postal_code',
      'invoicing_city',
      'invoicing_country',
      'invoicing_language',
      'invoicing_einvoice_address',
      'invoicing_einvoice_operator',
      'invoicing_tax_code'
    ]

    elementsToRemove.forEach(field => delete data[field])

    return data
  }

  const onSubmit = handleSubmit(async data => {
    try {
      const filteredData = removeUnusedFields(data, isInvoicingEnabled)

      const { name_aliases, ...rest } = filteredData
      const cleanData = { ...rest, name_aliases: name_aliases?.filter(Boolean) }

      const promise = mutateAsync(cleanData)
      await toast.promise(promise, {
        loading: t('general.saving'),
        success: t('general.changesSaved'),
        error: t('general.error')
      })
      onCreate && onCreate(await promise)
    } catch {
      return null
    }
  })

  const requiredInvoicingInputOptions = {
    required: { value: isInvoicingEnabled, message: t('validation.required') }
  }

  const onCopy = async (basicBusinessData: IBasicBusinessInfoFromPrh) => {
    try {
      const data = await fetchPrhSearch({ businessId: basicBusinessData.business_id })
      setBusinessData(data)
    } catch {
      toast.error(t('general.error'))
    }
  }

  const setBusinessData = (results: IPrhSearchResults) => {
    if (!results) {
      return
    }

    setValue('name', results.business_name)
    setValue('contact_business_id', results.business_id)
    setValue('invoicing_postal_code', results?.address?.post_code)
    setValue('invoicing_street', results?.address?.street)
    setValue('invoicing_country', results?.address?.country)
    setValue('invoicing_city', results?.address?.city)
  }

  return (
    <div onSubmit={event => event.stopPropagation()}>
      <FormProvider {...methods}>
        <form id="contact-form" onSubmit={onSubmit}>
          <Section>
            <Select
              label={t('contacts.form.type')}
              required={true}
              errors={errors?.type}
              {...register('type', {
                required: { value: true, message: t('validation.required') }
              })}
              data-test="contact-form-type"
            >
              <option disabled selected value=""></option>
              {CONTACT_TYPE_OPTIONS.map(({ labelKey, value }) => (
                <option key={labelKey} value={value}>
                  {t(labelKey)}
                </option>
              ))}
            </Select>

            {selectedType === ContactType.BUSINESS ? (
              <BusinessSearchDropdown
                label={t('contacts.form.name')}
                onCopy={onCopy}
              ></BusinessSearchDropdown>
            ) : (
              <Input
                label={t('contacts.form.name')}
                required={true}
                errors={errors?.name}
                {...register('name', {
                  required: { value: true, message: t('validation.required') }
                })}
                data-test="contact-form-name"
              />
            )}

            <Controller
              control={control}
              name="name_aliases"
              render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                <TextArea
                  inputRef={ref}
                  label={t('contacts.form.aliases')}
                  info={t('contacts.form.aliasesInfo')}
                  value={value?.join('\n')}
                  error={error}
                  onChange={e => {
                    const value = e.currentTarget.value
                    onChange(value.split('\n'))
                  }}
                />
              )}
            />

            {selectedType === ContactType.BUSINESS && (
              <motion.div>
                <BusinessIdDropdown
                  onCopy={setBusinessData}
                  required={isInvoicingEnabled}
                ></BusinessIdDropdown>
              </motion.div>
            )}

            <InvoicingContainer>
              <div className="switch-wrapper">
                <Controller
                  control={control}
                  name="is_invoicing_enabled"
                  render={({ field: { ref, value, onChange } }) => (
                    <Switch
                      id="enable-invoicing-switch"
                      ref={ref}
                      label={t('contacts.form.activateInvoicing')}
                      checked={value}
                      onChange={() => onChange(!value)}
                    />
                  )}
                />
              </div>

              {isInvoicingEnabled && (
                <>
                  <br />
                  <Input
                    label={t('contacts.form.email')}
                    required={true}
                    errors={errors?.invoicing_email}
                    {...register('invoicing_email', requiredInvoicingInputOptions)}
                  />
                  {selectedType === ContactType.BUSINESS && (
                    <>
                      <br />
                      <Input
                        label={t('contacts.form.einvoiceAddress')}
                        info={t('contacts.form.einvoiceAddressInfo')}
                        errors={errors?.invoicing_einvoice_address}
                        {...register('invoicing_einvoice_address')}
                      />
                      <Input
                        label={t('contacts.form.einvoiceOperator')}
                        info={t('contacts.form.einvoiceAddressInfo')}
                        errors={errors?.invoicing_einvoice_operator}
                        {...register('invoicing_einvoice_operator')}
                      />
                      <br />
                      <Input
                        label={t('contacts.form.taxNumber')}
                        info={t('contacts.form.taxNumberInfo')}
                        errors={errors?.invoicing_tax_code}
                        {...register('invoicing_tax_code')}
                      />
                    </>
                  )}
                  <Input
                    required={true}
                    label={t('contacts.form.invoicingStreet')}
                    errors={errors?.invoicing_street}
                    {...register('invoicing_street', requiredInvoicingInputOptions)}
                  />
                  <Input
                    required={true}
                    label={t('contacts.form.invoicingPostalCode')}
                    errors={errors?.invoicing_postal_code}
                    {...register('invoicing_postal_code', requiredInvoicingInputOptions)}
                  />
                  <Input
                    required={true}
                    label={t('contacts.form.invoicingCity')}
                    errors={errors?.invoicing_city}
                    {...register('invoicing_city', requiredInvoicingInputOptions)}
                  />
                  <Input
                    required={true}
                    label={t('contacts.form.invoicingCountry')}
                    errors={errors?.invoicing_country}
                    {...register('invoicing_country', requiredInvoicingInputOptions)}
                  />
                  <Select
                    required={true}
                    label={t('contacts.form.invoicingLanguage')}
                    info={t('contacts.form.invoicingLanguageHint')}
                    errors={errors?.invoicing_language}
                    {...register('invoicing_language', requiredInvoicingInputOptions)}
                  >
                    <option disabled selected value=""></option>
                    {INVOICING_LANGUAGE_OPTIONS.map(({ labelKey, value }) => (
                      <option key={labelKey} value={value}>
                        {t(labelKey)}
                      </option>
                    ))}
                  </Select>
                </>
              )}
            </InvoicingContainer>

            <br />
            <motion.div layout="position">
              <TextArea
                label={t('contacts.form.notes')}
                errors={errors?.notes}
                {...register('notes')}
              />
            </motion.div>
          </Section>
        </form>
      </FormProvider>
    </div>
  )
}

export const Section = styled.div`
  margin-bottom: 1rem;
`

const InvoicingContainer = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;

  .switch-wrapper > * {
    margin-bottom: 0;
  }
`
