import {
  Button,
  Modal,
  ModalContainer,
  ModalDialog,
  ModalDismissButton,
  ModalOpenButton
} from '@components'
import { IProduct } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductForm } from './ProductForm'

interface Props {
  product?: IProduct
  children: React.ReactNode
}

export const ProductFormModal: React.FC<Props> = ({ children, product }) => {
  const [t] = useTranslation()
  const header = product?.id ? t('products.form.modalTitleEdit') : t('products.form.modalTitleNew')

  return (
    <Modal>
      {({ hideModal }) => (
        <>
          <ModalOpenButton>{children}</ModalOpenButton>
          <ModalDialog width={600}>
            <ModalContainer
              header={header}
              onClose={() => hideModal()}
              footer={
                <>
                  <ModalDismissButton>
                    <Button>{t('general.cancel')}</Button>
                  </ModalDismissButton>
                  <Button type="submit" intent="success" form="product-form">
                    {t('general.save')}
                  </Button>
                </>
              }
            >
              <ProductForm product={product} onSubmit={() => hideModal()} />
            </ModalContainer>
          </ModalDialog>
        </>
      )}
    </Modal>
  )
}
