import { Button, ButtonProps } from '@components'
import { getSettingsPageUrl } from '@constants'
import { useBusinessContext, useFeature } from '@context'
import { useTheme } from '@hooks'
import { AnimatePresence, motion } from 'framer-motion'
import React, { forwardRef } from 'react'
import { useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaExclamationTriangle, FaPaperclip } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ToastFilePreview } from './ToastFilePreview'

type FileUpload = (File) => Promise<any>

interface UploadFilesButtonProps extends ButtonProps {
  uploadFile: FileUpload
  fileTypes?: string[]
  disableFullAlert?: boolean
}

const useUpload = (
  uploadFile: FileUpload,
  noDrag = false,
  accept?: string[],
  disabled: boolean = false
) => {
  const theme = useTheme()
  return useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    disabled: disabled,
    noDrag,
    accept,
    onDrop: newFiles =>
      newFiles.map(file => {
        toast.promise(
          uploadFile(file),
          {
            loading: <ToastFilePreview file={file} />,
            success: <ToastFilePreview file={file} />,
            error: <ToastFilePreview file={file} />
          },
          {
            style: {
              borderRadius: '1rem',
              background: theme.colors.menuBg,
              color: theme.colors.neutralWhite
            }
          }
        )
      })
  })
}

export const UploadFilesButton = forwardRef<any, UploadFilesButtonProps>(
  ({ uploadFile, fileTypes, children, disableFullAlert = false, ...rest }, ref) => {
    const [t] = useTranslation()
    const { businessId } = useBusinessContext()
    const isFull = !useFeature('feature_unlimited_storage', true) && !disableFullAlert

    const { open, getRootProps, getInputProps } = useUpload(uploadFile, true, fileTypes)

    if (isFull) {
      return (
        <StyledButtonWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <Link to={getSettingsPageUrl(businessId, { page: 'plan' })}>
            <StyledFilesFullAlert>
              <FaExclamationTriangle /> {t('components.uploadButton.fullArchive')}
            </StyledFilesFullAlert>
          </Link>
        </StyledButtonWrapper>
      )
    }

    return (
      <StyledButtonWrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <Button ref={ref} {...rest} onClick={() => open()}>
          {children}
        </Button>
      </StyledButtonWrapper>
    )
  }
)

export const UploadDropzone: React.FC<UploadFilesButtonProps> = ({
  uploadFile,
  fileTypes,
  children,
  disabled = false
}) => {
  const { getRootProps, getInputProps, isDragActive } = useUpload(
    uploadFile,
    false,
    fileTypes,
    disabled
  )
  const [t] = useTranslation()

  return (
    <StyledUploadDropzone {...getRootProps()}>
      <input {...getInputProps()} />
      {children}

      <AnimatePresence>
        {isDragActive && (
          <UploadOverlay>
            <UploadText>
              <FaPaperclip />
              <>{t('components.fileSelector.dropHere')}</>
            </UploadText>
          </UploadOverlay>
        )}
      </AnimatePresence>
    </StyledUploadDropzone>
  )
}

const StyledButtonWrapper = styled.div`
  position: relative;
  display: flex;

  a {
    display: flex;
    text-decoration: none !important;
  }
`

const StyledFilesFullAlert = styled.div`
  display: flex;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  border: 2px solid ${({ theme }) => theme.colors.nocfoYellow};
  color: ${({ theme }) => theme.colors.nocfoYellow};
  border-radius: 1rem;
  font-weight: 500;
`

const StyledUploadDropzone = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const overlayVariants = {
  show: {
    opacity: 1
  },
  hide: {
    opacity: 0
  }
}

const textVariants = {
  show: {
    scale: 1,
    opacity: 1
  },
  hide: {
    scale: 0.9,
    opacity: 0
  }
}

const UploadOverlay = styled(motion.div).attrs({
  variants: overlayVariants,
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border: 2px dashed ${({ theme }) => theme.colors.nocfoBlue};
  background-color: ${({ theme }) => theme.colors.nocfoBlue}22;
  z-index: 10;
  display: flex;
  justify-content: center;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const UploadText = styled(motion.div).attrs({ variants: textVariants })`
  align-self: center;
  color: white;
  font-weight: bold;
  border-radius: 0.6rem;
  padding: 0.3rem 0.7rem;
  background: white;
  background-color: ${({ theme }) => theme.colors.nocfoBlue};
  display: flex;

  & > * {
    align-self: center;
  }

  svg {
    margin-right: 0.4rem;
  }
`
