import { Avatar, Button, Input, ModalV2, Prompt, Select, TextTooltip } from '@components'
import { UserRole } from '@constants'
import { PermissionBoundary, useBusinessContext } from '@context'
import { deleteUsership, IPerson, updateUsership } from '@query'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaPen, FaSave, FaTrashAlt } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface Props {
  usership: IPerson
}

export const UserItem: React.FC<Props> = ({ usership }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const queryClient = useQueryClient()
  const { businessId, data } = useBusinessContext()
  const ownerEmail = data?.owner_email
  const isOwner = ownerEmail?.toLowerCase() === usership?.email?.toLowerCase()
  const [t] = useTranslation()

  const { register, handleSubmit } = useForm<IPerson>({ defaultValues: usership })

  const fullName = [usership.first_name, usership.last_name].join(' ').trim()

  const deleteUsershipMutation = useMutation(
    () => deleteUsership({ businessId, usershipId: usership?.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'people'])
        setShowDelete(false)
      },
      onError: ({ status }) => {
        if (status === 423) {
          toast.error(t('settings.people.cantDeleteYourself'))
        } else {
          toast.error(t('general.error'))
        }
      }
    }
  )

  const updateUsershipMutation = useMutation<unknown, unknown, Partial<IPerson>>(
    data => updateUsership({ businessId, usershipId: usership?.id }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'people'])
        setShowEdit(false)
      },
      onError: () => {
        toast.error(t('general.error'))
      }
    }
  )

  return (
    <>
      <UserItemWrapper>
        <Avatar src={usership.avatar_url} size={40} role={usership.role} />

        <ContentWrapper>
          {fullName && <StyledName>{fullName}</StyledName>}
          <StyledEmail>{usership.email}</StyledEmail>
        </ContentWrapper>

        <ActionContainer>
          <PermissionBoundary requireRole={UserRole.ADMIN}>
            <Button
              type="button"
              isSecondary={true}
              onClick={() => setShowEdit(true)}
              icon={<FaPen />}
            />

            {isOwner ? (
              <TextTooltip tooltip={t('settings.people.cannotDeleteOwner')}>
                <Button
                  type="button"
                  disabled={isOwner}
                  isSecondary={true}
                  onClick={() => setShowDelete(true)}
                  icon={<FaTrashAlt />}
                />
              </TextTooltip>
            ) : (
              <Button
                type="button"
                intent="danger"
                disabled={isOwner}
                isSecondary={true}
                onClick={() => setShowDelete(true)}
                icon={<FaTrashAlt />}
              />
            )}
          </PermissionBoundary>
        </ActionContainer>
      </UserItemWrapper>

      <ModalV2
        header={t('settings.people.edit')}
        isVisible={showEdit}
        handleOnClose={() => setShowEdit(false)}
        footer={
          <ModalFooter>
            <Button type="submit">{t('general.cancel')}</Button>

            <Button
              intent="success"
              icon={<FaSave />}
              type="submit"
              form="user-edit-form"
              disabled={updateUsershipMutation.isLoading}
              showSpinner={updateUsershipMutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </ModalFooter>
        }
      >
        <form
          id="user-edit-form"
          onSubmit={handleSubmit(data => {
            updateUsershipMutation.mutate(data)
          })}
        >
          <Input label={t('settings.people.email')} disabled={true} value={usership.email} />

          <Select
            label={t('settings.people.role')}
            id="email"
            required={true}
            {...register('role', {
              required: { value: true, message: t('validation.required') }
            })}
          >
            <option value="ADMIN">{t('settings.people.admin')}</option>
            <option value="EDITOR">{t('settings.people.editor')}</option>
            <option value="READ_ONLY">{t('settings.people.readOnly')}</option>
          </Select>
        </form>
      </ModalV2>

      <Prompt
        isVisible={showDelete}
        title={t('settings.people.deleteUser')}
        description={t('settings.people.deleteUserConfirm', { userEmail: usership?.email })}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            intent: 'default',
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            intent: 'danger',
            text: t('general.delete'),
            icon: <FaTrashAlt />,
            action: () => deleteUsershipMutation.mutateAsync(),
            showSpinner: deleteUsershipMutation.isLoading,
            disabled: deleteUsershipMutation.isLoading
          }
        ]}
      />
    </>
  )
}

const UserItemWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.md}rem 0;
  gap: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledEmail = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
`

const StyledName = styled.div`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: bold;
`

const ActionContainer = styled.div`
  display: flex;
`

const ModalFooter = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`
