import nocfoSrc from '@assets/nocfo-black.svg'
import { Alert, AnimatedContentLoader, Button, Input, Select, Switch } from '@components'
import { useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import {
  SlideContent,
  SlideFooter,
  SlideText,
  SlideTitle,
  Spacer
} from '@pages/BankIntegrationOnboardingPage/slides/slide.styled'
import {
  deleteBankIntegration,
  fetchBankIntegration,
  IBankIntegration,
  updateBankIntegration
} from '@query'
import { useAssistantAccounts } from '@utils'
import { tryFormatIBAN } from '@utils/iban'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { Controller, get, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaArrowsAltH, FaCheck } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

interface Props {
  isEdit: boolean
  goBack: () => void
  onDone: () => void
}

const FINALIZE_FORM_ID = 'finalize-form'

export const FinalizeSlide: React.FC<Props> = ({ isEdit, onDone, goBack }) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()
  const [integrationId] = useQueryParam('id')
  const { bankAccountOptions } = useAssistantAccounts()

  const {
    data: integration,
    isLoading,
    isIdle,
    refetch
  } = useQuery(
    [businessId, 'bank_integrations'],
    () => fetchBankIntegration({ businessId, integrationId }),
    {
      enabled: !!integrationId
    }
  )

  const { mutateAsync: updateIntegration } = useMutation<
    unknown,
    unknown,
    Partial<IBankIntegration>
  >(data => updateBankIntegration({ businessId, integrationId: integration.id }, data))

  const { mutateAsync: deleteIntegration, isLoading: isDeleting } = useMutation(() =>
    deleteBankIntegration({ businessId, integrationId: integration.id })
  )

  const handleGoBack = useCallback(async () => {
    if (!isEdit) {
      await deleteIntegration()
    }
    goBack()
  }, [])

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors }
  } = useForm<IBankIntegration>({
    defaultValues: async () => {
      const { data: integration } = await refetch()
      return {
        ...integration,
        accounts: integration.accounts.some(({ enabled }) => enabled)
          ? // CASE: At least one account is enabled
            // > Keep accounts as they are
            integration.accounts
          : // CASE: No account is enabled
            // > Enable the first account by default
            integration.accounts.map((account, index) => ({
              ...account,
              enabled: index === 0
            }))
      }
    }
  })
  const { fields: accountFields } = useFieldArray({
    control,
    name: 'accounts',
    keyName: '__id'
  })

  const maxHistoricalDate = dayjs().subtract(integration?.max_historical_days, 'day')
  const maxHistoricalDateISO = maxHistoricalDate.format('YYYY-MM-DD')

  const onSubmit = handleSubmit(async data => {
    await updateIntegration({
      ...data,
      enabled: true // Mark integration as enabled
    })
    onDone()
  })

  const showSpinner = isLoading || isIdle

  return (
    <>
      <SlideContent>
        <StyledForm id={FINALIZE_FORM_ID} onSubmit={onSubmit}>
          <AnimatedContentLoader isLoading={showSpinner}>
            {isEdit ? (
              <SlideTitle>
                {t('bankOnboarding.finalize.editTitle', { bankName: integration?.name })}
              </SlideTitle>
            ) : (
              <SlideTitle>
                {t('bankOnboarding.finalize.createTitle', { bankName: integration?.name })}
              </SlideTitle>
            )}
            <SlideText>{t('bankOnboarding.finalize.description')}</SlideText>
            <LogoWrapper>
              <LogoImg src={integration?.logo} className="left" />
              <FaArrowsAltH />
              <LogoImg src={nocfoSrc} className="right" />
            </LogoWrapper>

            <Spacer />

            {accountFields.map((account, index) => {
              return (
                <StyledAccountWrapper key={`account-${account.id}`}>
                  <StyledAccountHeaderWrapper>
                    <label htmlFor={`accounts-${index}-enabled-switch`}>
                      <StyledAccountTitle>
                        {t('bankOnboarding.finalize.accountTitle')}
                      </StyledAccountTitle>
                      <StyledAccountIdentifier>
                        {tryFormatIBAN(account.identifier)}
                      </StyledAccountIdentifier>
                    </label>

                    <Controller
                      control={control}
                      name={`accounts.${index}.enabled`}
                      render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                        <Switch
                          label=""
                          height={24}
                          id={`accounts-${index}-enabled-switch`}
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </StyledAccountHeaderWrapper>

                  {watch(`accounts.${index}.enabled`) && (
                    <>
                      <Controller
                        control={control}
                        name={`accounts.${index}.account_id`}
                        rules={{
                          required: { value: true, message: t('validation.required') }
                        }}
                        render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                          <>
                            <Select
                              id={`accounts-${index}-account-select`}
                              label={t('bankOnboarding.finalize.accountLabel')}
                              ref={ref}
                              error={error}
                              value={value}
                              onChange={e => {
                                // Convert value as number
                                onChange(parseInt(e.target.value, 10))
                              }}
                              required={true}
                              errors={get(errors, `accounts.${index}.account_id`)}
                            >
                              <option value="" hidden>
                                {t('bankOnboarding.finalize.selectAccount')}
                              </option>
                              <option value="0">
                                {'+ ' + t('bankOnboarding.finalize.newAccount')}
                              </option>
                              {bankAccountOptions.map(account => (
                                <option key={`account-option-${account.id}`} value={account.id}>
                                  {account.number} {account.name}
                                </option>
                              ))}
                            </Select>
                            <Alert
                              isVisible={value === 0}
                              description={t('bankOnboarding.finalize.newAccountDescription', {
                                bankName: integration?.name
                              })}
                            />
                          </>
                        )}
                      />

                      <Input
                        type="date"
                        label={t('settings.integrations.bank.minSyncDate')}
                        info={t('settings.integrations.bank.minSyncDateInfo', {
                          maxHistoricalDays: integration?.max_historical_days
                        })}
                        required={true}
                        min={maxHistoricalDateISO}
                        errors={get(errors, `accounts.${index}.begin_sync_from`)}
                        {...register(`accounts.${index}.begin_sync_from`, {
                          required: { value: true, message: t('validation.required') }
                        })}
                      />
                    </>
                  )}
                </StyledAccountWrapper>
              )
            })}
            <Spacer />
          </AnimatedContentLoader>
        </StyledForm>
      </SlideContent>
      <SlideFooter>
        <Button
          type="button"
          icon={<FaArrowLeft />}
          onClick={() => handleGoBack()}
          showSpinner={isDeleting}
          disabled={isDeleting}
        >
          {t('general.back')}
        </Button>
        <Spacer />
        <Button
          type="submit"
          intent="success"
          icon={<FaCheck />}
          form={FINALIZE_FORM_ID}
          disabled={showSpinner}
        >
          {isEdit ? t('general.save') : t('bankOnboarding.finalize.activate')}
        </Button>
      </SlideFooter>
    </>
  )
}

const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  overflow: hidden;

  gap: ${({ theme }) => theme.spacing.sm}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;

  .input-wrapper {
    margin: 0;

    input {
      height: 1.6rem;
    }
  }
`

const StyledAccountHeaderWrapper = styled.div`
  display: flex;
  width: 100%;

  label {
    width: 100%;
    flex: 1;
    margin: 0;
  }

  .switch-wrapper {
    margin: 0;
  }
`

const StyledAccountTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const StyledAccountIdentifier = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const LogoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  justify-content: space-around;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.xxl}rem 0;

  svg {
    width: 2rem;
    height: 2rem;
    min-width: 1rem;
    min-height: 1rem;
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }
`

const LogoImg = styled.div<{ src: string }>`
  width: 100%;
  max-width: 200px;
  height: 50px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
