import { useBusinessContext } from '@context'
import { useLocalStorage } from '@hooks'
import { fetchFilters, IDashboardFilter, IDateRangeKey } from '@query'
import dayjs from 'dayjs'
import React, { useCallback, useContext, useMemo } from 'react'
import { QueryObserverResult, useQuery } from 'react-query'

export interface ICustomDateRange {
  dateFrom: string
  dateTo: string
}

interface Props {
  selectedRange: IDashboardFilter
  options: IDashboardFilter[]
  isReady: boolean
  isActive: boolean
  reset: () => void
  setRange: (range: IDashboardFilter) => void
  refetch: () => Promise<QueryObserverResult<IDashboardFilter[], unknown>>
}

export const DateRangeContext = React.createContext<Props>({} as Props)

export const useDateRangeContext = (): Props => {
  return useContext(DateRangeContext)
}

const buildCustomDateRange = (newRange: IDashboardFilter) => {
  const newDateFrom = dayjs(newRange.date_from, 'YYYY-MM-DD')
  const newDateTo = dayjs(newRange.date_to, 'YYYY-MM-DD')

  return {
    date_from: newDateFrom.isValid() ? newDateFrom.format('YYYY-MM-DD') : undefined,
    date_to: newDateTo.isValid() ? newDateTo.format('YYYY-MM-DD') : undefined
  }
}

export const DateRangeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { businessId } = useBusinessContext()

  const initialValue = useMemo<IDashboardFilter>(
    () => ({
      key: 'period-this',
      date_from: dayjs().startOf('month').format('YYYY-MM-DD'),
      date_to: dayjs().endOf('month').format('YYYY-MM-DD')
    }),
    []
  )

  const [range, setRange] = useLocalStorage<IDashboardFilter>(
    `DateRangeContext-${businessId}-dateRange`,
    initialValue
  )

  const validateAndSetRange = useCallback(
    (newRange: IDashboardFilter) => {
      setRange({
        ...newRange,
        ...(newRange.key === 'custom' ? buildCustomDateRange(newRange) : {})
      })
    },
    [setRange]
  )

  const {
    data: options_,
    isSuccess,
    refetch
  } = useQuery([businessId, 'date_ranges'], () => fetchFilters({ businessId }), {
    enabled: !!businessId
  })

  const [option, options] = useMemo(() => {
    // Add custom range to the options
    const options = [
      ...(options_ || []),
      {
        key: 'custom' as IDateRangeKey,
        date_from: range?.date_from,
        date_to: range?.date_to
      }
    ]

    // Select the option from options
    const option =
      options.find(option => option.key === range?.key) ||
      options.find(option => option.key === 'all-history')

    return [option, options]
  }, [businessId, options_, range])

  const reset = useCallback(() => {
    setRange(options.find(option => option.key === 'all-history'))
  }, [businessId, options])

  return (
    <DateRangeContext.Provider
      value={{
        selectedRange: option,
        options,
        reset,
        setRange: validateAndSetRange,
        isReady: isSuccess,
        isActive: option?.key && option?.key !== 'all-history',
        refetch
      }}
    >
      {children}
    </DateRangeContext.Provider>
  )
}
