/** is the given object a Function? */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (obj: unknown): obj is Function => typeof obj === 'function'

/** utility for calling all the provided arguments */
export const callAll =
  <GenericType>(...fns: Array<(...a: GenericType[]) => void>) =>
  (...args: GenericType[]): void =>
    fns.forEach(fn => fn && fn(...args))

export const handleOnEnterPress = (e: React.KeyboardEvent, callback: () => void): void => {
  if (e.key === 'Enter') {
    callback()
  }
}

export const capitalize = (s: string): string => {
  const [first, ...rest] = s
  return [first.toUpperCase(), ...rest].join('')
}

export const assertUnreachable = (message: string, value: never): void => {
  throw new Error(`Encountered unreachable entry: ${message} - ${value}`)
}

/**
 * Checks that the provided objects' contents are equal to each other.
 */
export const deepEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
    : x === y
}
