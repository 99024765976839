import { UniversalActionBar } from '@components'
import { useBusinessContext } from '@context'
import { createPeriod } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'

export const PeriodActionBar: React.FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()

  const { mutate, isLoading } = useMutation(() => createPeriod({ businessId }, {}), {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'periods'])
    }
  })

  return (
    <UniversalActionBar
      buttons={[
        {
          children: t('period.createNew'),
          icon: <FaPlus />,
          onClick: () => mutate(),
          showSpinner: isLoading,
          disabled: isLoading
        }
      ]}
    />
  )
}
