import { motion } from 'framer-motion'
import { FaSpinner } from 'react-icons/fa'
import styled, { css, keyframes } from 'styled-components'

export const Label = styled.label`
  display: flex;
  white-space: nowrap;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.metalGray};
  line-height: ${({ theme }) => theme.fontSize.sm}rem;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;

  & > * {
    align-self: center;
  }

  .required {
    color: ${({ theme }) => theme.colors.nocfoRed};
  }
`

export const StyledInputField = styled.input`
  flex-grow: 1;

  /* Fix styles on iOS */
  &::-webkit-date-and-time-value {
    min-height: 1em;
    width: 100%;
    text-align: left;
  }
`

export const StyledTextArea = styled.textarea`
  flex-grow: 1;
  background: transparent;
  border: none;
  min-height: 100px;
  max-height: 300px;
  outline: none;
`

export const StyledSelectField = styled.select`
  flex-grow: 1;
`

export const StateContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ theme }) => theme.iconSize.sm}em;

  & > svg {
    width: ${({ theme }) => theme.fontSize.md}rem;
    height: ${({ theme }) => theme.fontSize.md}rem;
  }
`

export const ErrorText = styled(motion.div)`
  color: ${({ theme }) => theme.colors.nocfoRed};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: -4px;
`

export const Info = styled.span`
  align-self: center;
  margin-left: 4px;

  svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.metalGray};
  }
`

export const InfoPopup = styled(motion.div).attrs({
  variants: {
    hide: { opacity: 0, scale: 0.7, x: '-85%', y: -20, pointerEvents: 'none' },
    open: { opacity: 1, scale: 1.0, x: '-100%', y: 0, pointerEvents: 'all' }
  },
  initial: 'hide',
  animate: 'open',
  exit: 'hide'
})`
  z-index: 10;
  position: absolute;
  background: ${({ theme }) => theme.colors.menuBg};
  color: white;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.14);
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  font-weight: normal;
  max-width: 400px;
  min-width: 250px;
  overflow: hidden;
  white-space: normal;
  line-height: 1.2rem;
  font-weight: 500;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing.md}rem;
`

const Spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

export const LoadingSpinner = styled(FaSpinner)`
  animation: ${Spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`

export const StyledInputWrapper = styled(motion.div)<{
  isFocused: boolean
  isInvalid: boolean
  hasClearSelection: boolean
}>(
  ({ theme, isFocused, isInvalid, hasClearSelection }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.neutralGray};
    margin-bottom: ${theme.spacing.xs}rem;
    border-radius: ${theme.spacing.md}rem;
    padding: ${theme.spacing.xs}rem;
    transition: 0.4s;
    box-shadow: 0px 0px 0px 2px transparent;
    border: 2px solid ${theme.colors.neutralGray};
    position: relative;

    input,
    select {
      flex-grow: 1;
      background: transparent !important;
      border: 0px;
      margin: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;

      ${hasClearSelection
        ? `
        padding-right: ${theme.spacing.xl}rem;
        text-overflow: ellipsis;
        `
        : ''}

      &:focus {
        outline: none;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &[list] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    ${isFocused &&
    css`
      background: white;
    `}

    ${isInvalid &&
    css`
      border: 2px solid ${theme.colors.nocfoRed};
      background: white;
    `}
  `
)

export const CheckboxWrapper = styled(motion.div)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;

  & > label,
  & > input {
    align-self: center;
    margin: 0;
  }

  & > input {
    width: ${({ theme }) => theme.fontSize.lg}rem;
    height: ${({ theme }) => theme.fontSize.lg}rem;
  }

  & > label {
    margin-left: 0.6rem;
  }
`

export const StyledReactSelectWrapper = styled.div`
  width: 100%;

  .advanced-select {
    width: 100%;
  }
`

export const EmptyIconWrapper = styled.div<{ isInfo: boolean }>`
  position: absolute;
  top: 50%;
  right: 0;
  padding: ${({ theme }) => theme.spacing.md}rem;
  padding-top: ${({ theme, isInfo }) => (isInfo ? theme.spacing.sm : theme.spacing.md)}rem;
  padding-right: ${({ theme, isInfo }) => (isInfo ? theme.spacing.xs : theme.spacing.md)}rem;
  margin-top: ${({ theme, isInfo }) => (isInfo ? theme.spacing.sm : 0)}rem;
  margin-right: ${({ theme, isInfo }) => (isInfo ? theme.spacing.md : 0)}rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.metalGray};

  svg {
    align-self: center;
    vertical-align: center;
  }
`
