import { generateError, InputProps, InputWrapper } from '@components'
import { useBusinessContext } from '@context'
import { useTheme } from '@hooks'
import { createContact, fetchContacts, IContact } from '@query'
import React, { forwardRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import ReactSelect from 'react-select/creatable'
import styled from 'styled-components'

export const ContactSelectDropdown: React.ForwardRefExoticComponent<InputProps> = forwardRef(
  (
    {
      value,
      onChange,
      errors,
      name,
      label,
      info,
      required = false,
      hideErrorText = false,
      ...rest
    },
    ref
  ) => {
    const [t] = useTranslation()
    const theme = useTheme()
    const { businessId } = useBusinessContext()
    const [isFocused, setFocused] = useState(false)
    const error = generateError(errors)
    const portalRoot = document.getElementById('dropdown-root')

    const { data, isLoading, refetch } = useQuery([businessId, 'contacts'], () =>
      fetchContacts({ businessId }, { page_size: 500 })
    )

    const items = data?.results?.map(result => ({ value: result.id, label: result.name }))

    const createMutation = useMutation<IContact, unknown, string>(name =>
      createContact(
        { businessId },
        {
          type: 'UNSET',
          name
        }
      )
    )

    const onCreateOption = async value => {
      const promise = createMutation.mutateAsync(value)
      toast.promise(promise, {
        loading: t('general.saving'),
        success: t('general.success'),
        error: t('general.error')
      })
      const data = await promise
      await refetch()
      onChange(data.id)
    }

    const handleChange = (newValue, { action }) => {
      if (action === 'clear') {
        onChange(null)
      } else {
        const selectedId = newValue?.value
        onChange(selectedId)
      }
    }

    const selected = items?.find(i => i.value === value)

    return (
      <InputWrapper
        inputId={name}
        label={label}
        isFocused={isFocused}
        isInvalid={Boolean(error)}
        hideErrorText={hideErrorText}
        errorMsg={error}
        info={info}
        required={required}
      >
        <StyledSelectWrapper data-test="document-form-contact-selection-input">
          <ReactSelect
            id={name}
            inputId={name}
            ref={ref as any}
            isClearable={true}
            className="advanced-select"
            menuPortalTarget={portalRoot}
            menuPosition="fixed"
            menuPlacement={'bottom'}
            options={items as any}
            formatCreateLabel={value => (
              <i>{t('components.contentSelectDropdown.createNew', { name: value })}</i>
            )}
            onCreateOption={onCreateOption}
            onChange={handleChange}
            backspaceRemovesValue={true}
            isLoading={createMutation.isLoading || isLoading}
            value={selected || null}
            {...rest}
            styles={{
              control: styles => ({
                ...styles,
                backgroundColor: 'transparent',
                border: 'none !important',
                outline: 'none !important',
                boxShadow: 'none',
                padding: 0,
                margin: 0,
                minHeight: 0,
                width: '100%'
              }),
              input: styles => ({
                ...styles,
                margin: 0,
                padding: 0,
                outline: 'none'
              }),
              placeholder: styles => ({
                ...styles,
                padding: 0,
                margin: 0,
                color: '#757575'
              }),
              valueContainer: styles => ({
                ...styles,
                padding: 0
              }),
              singleValue: styles => ({
                ...styles,
                padding: 0,
                margin: 0
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: 0
              }),
              clearIndicator: styles => ({
                ...styles,
                padding: 0
              }),
              indicatorSeparator: styles => ({
                ...styles,
                display: 'none'
              }),
              menuPortal: styles => ({ ...styles, zIndex: 9999 }),
              menu: styles => ({
                ...styles,
                borderRadius: '1rem',
                padding: '0.8rem',
                overflow: 'hidden',
                marginTop: '0.8rem',
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.2)'
              }),
              option: (styles, state) => ({
                ...styles,
                borderRadius: '0.5rem',
                background: state.isSelected
                  ? theme.colors.neutralGray
                  : state.isFocused
                  ? theme.colors.defaultHover
                  : 'white',
                color: theme.colors.neutralBlack
              })
            }}
            onFocus={e => {
              setFocused(true)
              rest.onFocus?.(e)
            }}
            onBlur={e => {
              setFocused(false)
              rest.onBlur?.(e)
            }}
            name={name}
          />
        </StyledSelectWrapper>
      </InputWrapper>
    )
  }
)

const StyledSelectWrapper = styled.div`
  width: 100%;
`
