import React from 'react'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  header?: string
  footer?: React.ReactNode
  onClose: () => void
  children: React.ReactNode
}

export const ModalContainer: React.FC<Props> = ({ header = '', footer, onClose, children }) => (
  <StyledContainer>
    <Header>
      <h3>{header}</h3>
      <FaTimes size={32} onClick={() => onClose()} />
    </Header>
    <Content>{children}</Content>
    {footer && <Footer>{footer}</Footer>}
  </StyledContainer>
)

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  background: white;

  padding: ${({ theme }) => theme.spacing.xxs}rem;

  @media only screen and (min-width: 800px) {
    padding: ${({ theme }) => theme.spacing.sm}rem;
  }
`

const Content = styled.div`
  position: relative;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.sm}rem;

  h3 {
    margin: 0;
  }

  & > * {
    align-self: center;
  }

  svg {
    cursor: pointer;
    padding: 8px;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
`
