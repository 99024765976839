import React from 'react'
import styled from 'styled-components'

interface Props extends React.DetailedHTMLProps<any, HTMLDivElement> {
  type?: 'info' | 'success' | 'error' | 'warning' | 'default'
  children: React.ReactNode
}

export const Badge: React.FC<Props> = ({ type = 'info', children, ...rest }) => {
  return (
    <StyledBadge className={type} {...rest}>
      {children}
    </StyledBadge>
  )
}

const StyledBadge = styled.div`
  display: inline-block;
  padding: 0.2rem 0.3rem;
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs}rem;

  &.info {
    background: ${({ theme }) => theme.colors.nocfoBlue}25;
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }

  &.success {
    background: ${({ theme }) => theme.colors.nocfoGreen}25;
    color: ${({ theme }) => theme.colors.nocfoGreen};
  }

  &.error {
    background: ${({ theme }) => theme.colors.nocfoRed}25;
    color: ${({ theme }) => theme.colors.nocfoRed};
  }

  &.warning {
    background: ${({ theme }) => theme.colors.nocfoYellow}25;
    color: ${({ theme }) => theme.colors.nocfoYellow};
  }

  &.default {
    background: ${({ theme }) => theme.colors.neutralGray};
    color: ${({ theme }) => theme.colors.metalGray};
  }
`
