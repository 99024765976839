import { Button, ButtonProps, FilterBar, SearchInput } from '@components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IFilter {
  id: string
  name: string
  filter: any
}

interface Props {
  showSearch?: boolean
  onSearch?: (string) => void
  searchPlaceholder?: string
  showFilter?: boolean
  onFilter?: (any) => void
  filters?: IFilter[]
  buttons?: ButtonProps[]
}

export const UniversalActionBar: React.FC<Props> = ({
  showSearch,
  onSearch,
  searchPlaceholder,
  showFilter,
  onFilter,
  filters,
  buttons
}) => {
  const [t] = useTranslation()

  // Guards
  if (showSearch && !onSearch) throw new Error('Missing `onSearch` handler')
  if (showFilter && !onFilter) throw new Error('Missing `onFilter` handler')
  if (showFilter && !filters) throw new Error('Missing `filters` options')

  return (
    <StyledActionBar>
      <Row>
        {showSearch && (
          <SearchInput
            placeholder={searchPlaceholder || t('general.search')}
            onSearch={value => onSearch(value)}
          />
        )}

        {buttons?.map((props, index) => (
          <Button key={`action-button-${index}`} {...props} />
        ))}
      </Row>

      {showFilter && (
        <Row>
          <FilterBar filters={filters} onFilter={onFilter} />
        </Row>
      )}
    </StyledActionBar>
  )
}

const StyledActionBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  justify-content: space-between;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.sm}rem;

  & > * {
    margin-bottom: 0;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  justify-content: flex-end;

  .badge {
    cursor: pointer;
    margin-right: 0.4rem;
  }
`
