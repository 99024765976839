import { Modal, ModalDialog } from '@components'
import { toggleGetStartedInfo } from '@query'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { Button } from '../Button'

export const GetStarted: React.FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const getStartedInfoMutation = useMutation(
    () => toggleGetStartedInfo({ show_get_started_info: false }),
    { onSuccess: () => queryClient.invalidateQueries('user') }
  )

  return (
    <Modal initiallyOpen onClose={() => getStartedInfoMutation.mutate()}>
      {({ hideModal }) => (
        <ModalDialog width={430} height={700}>
          <GetStartedContainer>
            <GetStartedPage>
              <GetStartedBody>
                <StyledGreetings>{t('getStarted.greetings')}</StyledGreetings>
                <StyledHeader>{t('getStarted.header')}</StyledHeader>
                <StyledSection>
                  <Trans i18nKey="getStarted.sections.first">
                    <a href={t('link.docs.main')} rel="noreferrer" target="_blank" />
                  </Trans>
                </StyledSection>
                <StyledSection>{t('getStarted.sections.second')}</StyledSection>
                <StyledSection>{t('getStarted.sections.third')}</StyledSection>
                <GetStartedButtonContainer>
                  <Button intent="primary" onClick={hideModal}>
                    {t('getStarted.confirm')}
                  </Button>
                </GetStartedButtonContainer>
              </GetStartedBody>
            </GetStartedPage>
          </GetStartedContainer>
        </ModalDialog>
      )}
    </Modal>
  )
}

const StyledSection = styled.p`
  padding-bottom: 2rem;
`

const StyledHeader = styled.h2`
  color: black;
  padding-bottom 2em;
`

const StyledGreetings = styled.h1`
  color: black;
  padding-bottom: 3.5rem;
`

const GetStartedContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  color: #425a70;
  overflow: auto;
`

const GetStartedPage = styled.div`
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const GetStartedBody = styled.div`
  padding-top: 4.5rem;
  text-align: center;
`

const GetStartedButtonContainer = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: center;
`
