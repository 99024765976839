import { AnimatedContentLoader, DoubleColumnLayout, DoubleColumnLayoutColumn } from '@components'
import { AccountType } from '@constants'
import { useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import { collectFromPages, fetchAccounts, IAccount } from '@query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { AccountActionBar, AccountDetails } from '../../components'
import { AccountList } from './AccountList'

export const NormalAccountPage: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [accountId, setAccountId] = useQueryParam<number>('id', value => parseInt(value, 10))
  const [search, setSearch] = useState('')
  const [filters, setFilter] = useState({})
  const { data, isFetched, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'accounts', { search, filters }],
    ({ pageParam = 1 }) =>
      fetchAccounts({ businessId }, {
        search,
        ...filters,
        is_shown: true,
        page: pageParam,
        page_size: 100
      } as any),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev,
      keepPreviousData: true
    }
  )
  const accounts = collectFromPages<IAccount>(data)

  const filterOptions = React.useMemo(
    () => [
      {
        id: 'is_used',
        name: t('accounts.actionBar.filterIsUsed'),
        filter: { is_used: true }
      },
      {
        id: 'bank',
        name: t('accounts.actionBar.filterBank'),
        filter: { type: [AccountType.ASS_PAY] }
      },
      {
        id: 'revenue',
        name: t('accounts.actionBar.filterRev'),
        filter: { type: [AccountType.REV, AccountType.REV_SAL, AccountType.REV_NO] }
      },
      {
        id: 'expense',
        name: t('accounts.actionBar.filterExp'),
        filter: { type: [AccountType.EXP, AccountType.EXP_NO, AccountType.EXP_50] }
      },
      {
        id: 'taxes',
        name: t('accounts.actionBar.filterTax'),
        filter: {
          type: [AccountType.EXP_TAX, AccountType.LIA_VAT, AccountType.ASS_VAT]
        }
      }
    ],
    []
  )

  return (
    <DoubleColumnLayout
      header={t('accounts.title')}
      isRightVisible={Boolean(accountId)}
      onRightClose={() => setAccountId(undefined)}
    >
      <DoubleColumnLayoutColumn>
        <PageWrapper>
          <AccountActionBar
            onSearch={s => setSearch(s)}
            onFilter={filters => setFilter(filters)}
            filters={filterOptions}
          />

          <AccountListWrapper>
            <AnimatedContentLoader
              isLoading={!isFetched}
              isEmpty={accounts.length === 0}
              isEmptyDescription={t('accounts.empty')}
            >
              <AccountList
                accounts={accounts}
                onScrollBottom={() => hasNextPage && fetchNextPage()}
              />
            </AnimatedContentLoader>
          </AccountListWrapper>
        </PageWrapper>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn isRight={true} innerKey={`account-${accountId}`}>
        {accountId && <AccountDetails accountId={accountId} />}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const PageWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const AccountListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`
