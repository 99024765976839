import { TextTooltip } from '@components'
import { InputValidationError } from '@utils/types/shared'
import { Switch as EGSwitch } from 'evergreen-ui'
import React, { forwardRef, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { FaQuestionCircle, FaTimes } from 'react-icons/fa'

import {
  CheckboxWrapper,
  EmptyIconWrapper,
  ErrorText,
  Label,
  Row,
  StyledInputField,
  StyledInputWrapper,
  StyledSelectField,
  StyledTextArea
} from './Input.styled'

export interface InputProps extends React.DetailedHTMLProps<any, HTMLInputElement> {
  label?: string
  errors?: any
  hideErrorText?: boolean
  info?: string
  required?: boolean
}

export interface InputWrapperProps {
  label: string | React.ReactNode
  inputId: string
  isInvalid?: boolean
  isFocused?: boolean
  errorMsg?: string
  hideErrorText?: boolean
  info?: string
  required?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
  clearSelection?: () => void
  showClearInput?: boolean
}

export const generateError = (error: FieldError | InputValidationError): string => {
  return error?.message ?? undefined
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
  isFocused,
  isInvalid,
  inputId,
  label,
  children,
  errorMsg,
  hideErrorText = false,
  required = false,
  style,
  clearSelection,
  showClearInput = false,
  info
}) => {
  return (
    <StyledInputWrapper
      className="input-wrapper"
      isFocused={isFocused}
      isInvalid={isInvalid}
      style={style}
      hasClearSelection={showClearInput}
    >
      <Label htmlFor={inputId}>
        <span style={{ flex: 1 }}>
          {label}
          {required && <span className="required"> *</span>}
        </span>
        {info && (
          <TextTooltip tooltip={info}>
            <FaQuestionCircle />
          </TextTooltip>
        )}
      </Label>
      <Row>
        {children}
        {clearSelection && showClearInput && (
          <EmptyIconWrapper
            isInfo={!!info}
            onClick={clearSelection}
            data-test="input-clear-selection"
          >
            <FaTimes />
          </EmptyIconWrapper>
        )}
      </Row>

      {!hideErrorText && errorMsg && (
        <ErrorText data-test="input-error-message">{errorMsg}</ErrorText>
      )}
    </StyledInputWrapper>
  )
}

export const Select = forwardRef<any, InputProps>(
  ({ errors, name, label, info, style, required = false, hideErrorText = false, ...rest }, ref) => {
    const [isFocused, setFocused] = useState(false)
    const error = generateError(errors)

    return (
      <InputWrapper
        inputId={name}
        label={label}
        isFocused={isFocused}
        isInvalid={Boolean(error)}
        hideErrorText={hideErrorText}
        errorMsg={error}
        info={info}
        required={required}
        style={style}
      >
        <StyledSelectField
          id={name}
          {...rest}
          onFocus={e => {
            setFocused(true)
            rest.onFocus?.(e)
          }}
          onBlur={e => {
            setFocused(false)
            rest.onBlur?.(e)
          }}
          name={name}
          ref={ref}
        />
      </InputWrapper>
    )
  }
)

export const Input = forwardRef<any, InputProps>(
  (
    {
      errors,
      name,
      label,
      info,
      required = false,
      hideErrorText = false,
      type = null,
      style,
      clearSelection,
      showClearInput,
      ...rest
    },
    ref
  ) => {
    const [isFocused, setFocused] = useState(false)
    const error = generateError(errors)

    return type !== 'hidden' ? (
      <InputWrapper
        inputId={rest.id || name}
        label={label}
        isFocused={isFocused}
        isInvalid={Boolean(error)}
        hideErrorText={hideErrorText}
        errorMsg={error}
        info={info}
        required={required}
        style={style}
        clearSelection={clearSelection}
        showClearInput={showClearInput}
      >
        <StyledInputField
          id={rest.id || name}
          type={type}
          {...rest}
          onWheel={type === 'number' ? () => false : null}
          onFocus={e => {
            setFocused(true)
            rest.onFocus?.(e)
          }}
          onBlur={e => {
            setFocused(false)
            rest.onBlur?.(e)
          }}
          name={name}
          ref={ref}
        />
      </InputWrapper>
    ) : (
      <input id={rest.id || name} name={name} ref={ref} type={type} {...rest}></input>
    )
  }
)

export const Checkbox = forwardRef<any, InputProps>(({ label = false, ...rest }, ref) => {
  return (
    <CheckboxWrapper>
      <input ref={ref} id={rest.id || rest.name} type="checkbox" {...rest} />
      <label htmlFor={rest.id || rest.name}>{label}</label>
    </CheckboxWrapper>
  )
})

export const Switch = forwardRef<any, InputProps>(({ label = false, ...rest }, ref) => {
  return (
    <CheckboxWrapper className="switch-wrapper">
      <EGSwitch ref={ref} id={rest.id || name} {...rest} />
      <label htmlFor={rest.id}>{label}</label>
    </CheckboxWrapper>
  )
})

export const TextArea = forwardRef<any, InputProps>(
  ({ errors, name, label, info, required = false, hideErrorText = false, ...rest }, ref) => {
    const [isFocused, setFocused] = useState(false)
    const error = generateError(errors)

    return (
      <InputWrapper
        inputId={rest.id || name}
        label={label}
        isFocused={isFocused}
        isInvalid={Boolean(error)}
        hideErrorText={hideErrorText}
        errorMsg={error}
        required={required}
        info={info}
      >
        <StyledTextArea
          id={rest.id || name}
          {...rest}
          onFocus={e => {
            setFocused(true)
            rest.onFocus?.(e)
          }}
          onBlur={e => {
            setFocused(false)
            rest.onBlur?.(e)
          }}
          name={name}
          ref={ref}
        />
      </InputWrapper>
    )
  }
)
