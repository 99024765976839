import {
  AnimatedContentLoader,
  AnimatedList,
  FileIcon,
  FilePreview,
  FileSelector,
  ModalV2,
  ToastFilePreview
} from '@components'
import { getFilesPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { useTheme } from '@hooks'
import { fetchFile, uploadFile } from '@query'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { FaExternalLinkSquareAlt, FaTimes } from 'react-icons/fa'
import { useQueries } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  StyledAttachmentSelector,
  StyledEmptyAttachmentSelector
} from './AttachmentSelector.styled'

interface Props {
  value: number[] // list of selected attachment ids
  onChange: (value: number[]) => void
  showRemoveInsteadOfViewLink?: boolean
  disabled?: boolean
}

export const AttachmentSelector: React.FC<Props> = ({
  value: attachmentIds = [],
  onChange,
  showRemoveInsteadOfViewLink,
  disabled
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [showArchive, setShowArchive] = useState(false)

  const { businessId } = useBusinessContext()
  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    disabled: disabled,
    noDragEventsBubbling: true,
    onDrop: async newFiles => {
      const promises = newFiles.map(file =>
        toast.promise(
          uploadFile(file, { businessId, fileName: file.name }),
          {
            loading: <ToastFilePreview file={file} />,
            success: <ToastFilePreview file={file} />,
            error: <ToastFilePreview file={file} />
          },
          {
            style: {
              borderRadius: '1rem',
              background: theme.colors.menuBg,
              color: theme.colors.neutralWhite
            }
          }
        )
      )
      const results = await Promise.all(promises)
      onChange([...attachmentIds, ...results.map(r => r.id)])
    }
  })

  return (
    <>
      {!disabled && attachmentIds.length === 0 && (
        <StyledEmptyAttachmentSelector
          isDragActive={isDragActive}
          {...getRootProps()}
          data-test="file-upload-dropzone"
        >
          <motion.div layout="position" className="add-more">
            <Trans i18nKey="attachmentSelector.dragOrAdd">
              <a key="link-1" href="#" onClick={() => open()} />
              <a key="link-2" href="#" onClick={() => setShowArchive(true)} />
            </Trans>
          </motion.div>
          <input {...getInputProps()} />
        </StyledEmptyAttachmentSelector>
      )}

      {attachmentIds.length > 0 && (
        <StyledAttachmentSelector isDragActive={isDragActive} {...getRootProps()}>
          <AttachmentOverview
            attachmentIds={attachmentIds}
            onChange={onChange}
            showRemoveInsteadOfViewLink={showRemoveInsteadOfViewLink}
          />

          {!disabled && (
            <>
              <div className="add-more">
                <Trans i18nKey="attachmentSelector.dragOrAdd">
                  <a key="link-1" href="#" onClick={() => open()} />
                  <a key="link-2" href="#" onClick={() => setShowArchive(true)} />
                </Trans>
              </div>
              <input {...getInputProps()} />
            </>
          )}
        </StyledAttachmentSelector>
      )}

      <ModalV2
        header={t('attachmentSelector.title')}
        width={700}
        height={900}
        isVisible={showArchive}
        handleOnClose={() => setShowArchive(false)}
      >
        <FileSelector
          businessId={businessId}
          initialSelections={attachmentIds}
          onClose={ids => {
            onChange(ids)
            setShowArchive(false)
          }}
        />
      </ModalV2>
    </>
  )
}

interface AttachmentOverviewProps {
  attachmentIds: number[]
  onChange: (ids: number[]) => void
  showRemoveInsteadOfViewLink?: boolean
}

export const AttachmentOverview: React.FC<AttachmentOverviewProps> = ({
  attachmentIds,
  onChange,
  showRemoveInsteadOfViewLink = false
}) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  const results = useQueries(
    attachmentIds.map(fileId => ({
      queryKey: [businessId, 'files', fileId],
      queryFn: () => fetchFile({ businessId, fileId })
    }))
  )
  const isLoading = results.some(result => result.isLoading)
  const isSuccess = results.every(result => result.isSuccess)
  const files = isSuccess ? results.map(result => result.data) : []

  return (
    <AnimatedContentLoader
      isLoading={isLoading}
      isEmpty={files.length === 0}
      isEmptyDescription={t('document.noAttachments')}
    >
      <FileWrapper>
        <AnimatedList
          items={files.map(file => (
            <FilePreview key={file.id} fileId={file.id}>
              <FileLineWrapper>
                <FileIcon
                  size={24}
                  filetype={file.type}
                  filename={file.name}
                  thumb={file.file}
                  blurhash={file.blurhash}
                />

                {showRemoveInsteadOfViewLink ? (
                  <FaTimes
                    onClick={() => {
                      onChange(attachmentIds.filter(id => id !== file.id))
                    }}
                  />
                ) : (
                  <Link
                    to={getFilesPageUrl(businessId, {
                      id: file.id,
                      idType: 'file',
                      folder: file.folder_id
                    })}
                    onClick={e => e.stopPropagation()}
                  >
                    <FaExternalLinkSquareAlt />
                  </Link>
                )}
              </FileLineWrapper>
            </FilePreview>
          ))}
        />
      </FileWrapper>
    </AnimatedContentLoader>
  )
}

const FileWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.md}rem;

  & > * {
    width: 100%;
  }
`

const FileLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;

  & > * {
    margin: 0;
  }

  svg {
    color: ${({ theme }) => theme.colors.iconGray};
  }

  svg:hover {
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }
`
