import { StepBlock, ToolBar } from '@components'
import { useBusinessContext } from '@context'
import { IVatPeriod, lockVatPeriod, reportVatPeriod } from '@query'
import { Pane, Text } from 'evergreen-ui'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaLock } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'

interface Props {
  vatPeriod: IVatPeriod
}

export const VatPeriodSteps: React.FC<Props> = ({ vatPeriod }) => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()

  const lockMutation = useMutation(() => lockVatPeriod({ businessId, vatPeriodId: vatPeriod.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'vat_periods'])
      queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriod.id])
      toast.success(t('vatPage.lockSuccess'))
    },
    onError: ({ data }) => {
      if ('has_drafts' in data) {
        toast.error(t('vatPage.hasDrafts'))
      } else {
        toast.error(t('error.oho'))
      }
    }
  })

  const reportMutation = useMutation(
    () => reportVatPeriod({ businessId, vatPeriodId: vatPeriod.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'vat_periods'])
        queryClient.invalidateQueries([businessId, 'vat_periods', vatPeriod.id])
        toast.success(t('vatPage.reportSuccess'))
      },
      onError: () => {
        toast.error(t('error.oho'))
      }
    }
  )

  const LockDescription: React.FC = () => {
    if (!vatPeriod.has_ended) {
      return <Text color="dimmed">{t('vatPage.waitForEnd')}</Text>
    }

    if (vatPeriod.is_locked) {
      return <Text color="dimmed">{t('vatPage.periodIsLocked')}</Text>
    }

    return (
      <Pane>
        <Pane marginBottom={8}>
          <Text>{t('vatPage.verify')}</Text>
        </Pane>
        <ToolBar
          align="left"
          items={[
            {
              text: t('vatPage.lock'),
              icon: FaLock,
              primary: true,
              disabled: vatPeriod.is_locked,
              action: () => {
                lockMutation.mutate()
              }
            }
          ]}
        />
      </Pane>
    )
  }

  const AccountDescription: React.FC = () => {
    const [t] = useTranslation()
    if (!vatPeriod.is_locked) {
      return <Text color="dimmed">{t('vatPage.waitForLock')}</Text>
    }

    if (vatPeriod.is_reported) {
      return <Text color="dimmed">{t('vatPage.hasBeenReported')}</Text>
    }

    return (
      <Pane>
        <Pane marginBottom={8}>
          <Text>{t('vatPage.youHaveLocked')}</Text>
        </Pane>
        <ToolBar
          align="left"
          items={[
            {
              text: t('vatPage.markReported'),
              icon: FaCheckCircle,
              primary: true,
              disabled: vatPeriod.is_reported,
              action: () => {
                reportMutation.mutate()
              }
            }
          ]}
        />
      </Pane>
    )
  }

  return (
    <>
      <StepBlock
        title={t('vatPage.waitForEnd')}
        isBlocked={false}
        isCompleted={vatPeriod.has_ended}
      />

      <StepBlock
        title={t('vatPage.lock')}
        descCurrent={<LockDescription />}
        isBlocked={!vatPeriod.has_ended}
        isCompleted={vatPeriod.is_locked}
      />

      <StepBlock
        isLast={true}
        title={t('vatPage.report')}
        descCurrent={<AccountDescription />}
        isBlocked={!vatPeriod.is_locked}
        isCompleted={vatPeriod.is_reported}
      />
    </>
  )
}
