import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import styled from 'styled-components'

export interface ButtonProps extends React.DetailedHTMLProps<any, HTMLButtonElement> {
  intent?: 'success' | 'primary' | 'default' | 'warning' | 'danger'
  isSecondary?: boolean
  icon?: React.ReactElement
  iconRight?: React.ReactElement
  showSpinner?: boolean
}

export const Button = React.forwardRef<any, ButtonProps>(
  ({ intent, isSecondary, icon, iconRight, showSpinner, children, ...rest }, ref) => {
    const showLeftIcon = !!icon && !iconRight
    const showRightIcon = !!iconRight && !icon
    const showLeftSpinner = !iconRight && showSpinner
    const showRightSpinner = !!iconRight && !icon && showSpinner

    return (
      <StyledButton
        ref={ref}
        className={[intent, isSecondary ? 'is-secondary' : ''].join(' ')}
        disabled={showSpinner}
        {...rest}
      >
        {(showLeftIcon || showLeftSpinner) && (
          <AnimatePresence initial={false} mode="wait">
            {showLeftSpinner ? (
              <IconWrapper key="spin">
                <FaSpinner className="spin" />
              </IconWrapper>
            ) : (
              icon && <IconWrapper key="icon">{icon}</IconWrapper>
            )}
          </AnimatePresence>
        )}
        {children && <span>{children}</span>}

        {(showRightIcon || showRightSpinner) && (
          <AnimatePresence initial={false} mode="wait">
            {showRightSpinner ? (
              <IconWrapper key="spin">
                <FaSpinner className="spin" />
              </IconWrapper>
            ) : (
              iconRight && <IconWrapper key="icon">{iconRight}</IconWrapper>
            )}
          </AnimatePresence>
        )}
      </StyledButton>
    )
  }
)

const StyledButton = styled(motion.button).attrs({
  whileHover: { scale: 1.05 },
  whileTap: { scale: 0.95 }
})`
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  border: none;
  background: ${({ theme }) => theme.colors.mainBg};
  font-weight: 500;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.metalGray};
  text-decoration: none !important;

  &.is-secondary {
    background: transparent;
    color: ${({ theme }) => theme.colors.metalGray};
  }

  & > * {
    align-self: center;
    text-decoration: none !important;
  }

  &.success {
    background: ${({ theme }) => theme.colors.nocfoGreen};
    color: white;

    &.is-secondary {
      background: transparent;
      color: ${({ theme }) => theme.colors.nocfoGreen};
    }
  }

  &.primary {
    background: ${({ theme }) => theme.colors.nocfoBlue};
    color: white;

    &.is-secondary {
      background: transparent;
      color: ${({ theme }) => theme.colors.nocfoBlue};
    }
  }

  &.warning {
    background: ${({ theme }) => theme.colors.nocfoYellow};
    color: ${({ theme }) => theme.colors.neutralBlack};

    &.is-secondary {
      background: transparent;
      color: ${({ theme }) => theme.colors.nocfoYellow};
    }
  }

  &.danger {
    background: ${({ theme }) => theme.colors.nocfoRed};
    color: white;

    &.is-secondary {
      background: transparent;
      color: ${({ theme }) => theme.colors.nocfoRed};
    }
  }

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }
`

const IconWrapper = styled(motion.div).attrs({
  variants: {
    hide: { scale: 0.8, opacity: 0 },
    show: { scale: 1, opacity: 1 }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  align-self: center;
  display: flex;
  & > * {
    align-self: center;
  }
`
