import {
  Button,
  Modal,
  ModalContainer,
  ModalDialog,
  ModalDismissButton,
  ModalOpenButton
} from '@components'
import { IContact } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactForm } from './ContactForm'
interface Props {
  contact?: IContact
  children: React.ReactNode
}

export const ContactFormModal: React.FC<Props> = ({ children, contact }) => {
  const [t] = useTranslation()
  return (
    <Modal>
      {({ hideModal }) => (
        <>
          <ModalOpenButton>{children}</ModalOpenButton>

          <ModalDialog width={600}>
            <ModalContainer
              header={t('contacts.newContact')}
              onClose={() => hideModal()}
              footer={
                <>
                  <ModalDismissButton>
                    <Button>{t('general.cancel')}</Button>
                  </ModalDismissButton>
                  <Button type="submit" intent="success" form="contact-form">
                    {t('general.save')}
                  </Button>
                </>
              }
            >
              <ContactForm contact={contact} onClose={() => hideModal()} />
            </ModalContainer>
          </ModalDialog>
        </>
      )}
    </Modal>
  )
}
