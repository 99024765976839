import { UniversalActionBar } from '@components'
import { getVATPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { createVatPeriod } from '@query'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

export const VatActionBar: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()

  const { mutateAsync, isLoading } = useMutation(() => createVatPeriod({ businessId }, {}), {
    onSuccess: data => {
      const newVatPeriodUrl = getVATPageUrl(businessId, { id: data.id })
      history.push(newVatPeriodUrl)

      queryClient.invalidateQueries([businessId, 'vat_periods'])
      toast.success(t('vatPage.periodCreated'))
    }
  })

  return (
    <UniversalActionBar
      buttons={[
        {
          children: t('vatPage.createNew'),
          icon: <FaPlus />,
          onClick: () => mutateAsync(),
          showSpinner: isLoading,
          disabled: isLoading
        }
      ]}
    />
  )
}
