import { getProductPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { useQueryParam, useVirtualList } from '@hooks'
import { IProduct } from '@query'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ProductListProps {
  products: IProduct[]
}

export const ProductList: React.FC<ProductListProps> = ({ products }) => {
  const [selectedId] = useQueryParam<number>('id', value => parseInt(value, 10))

  const { scrollRef, renderItems } = useVirtualList({
    items: products,
    itemSize: 67
  })

  return (
    <ProductListScrollable ref={scrollRef}>
      <AnimatePresence>
        {renderItems((product, style, index) => (
          <motion.div key={product.id} layout="position" style={style}>
            <ProductListItem
              key={`product-${product.id}`}
              product={product}
              isSelected={selectedId === product.id}
              isFirst={index === 0}
              isLast={index === products.length - 1}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </ProductListScrollable>
  )
}

interface ProductItemProps {
  product: IProduct
  isSelected: boolean
  isFirst: boolean
  isLast: boolean
}

const ProductListItem: React.FC<ProductItemProps> = ({ product, isFirst, isLast, isSelected }) => {
  const { businessId } = useBusinessContext()

  return (
    <ProductItemWrapper
      to={getProductPageUrl(businessId, { id: product.id })}
      className={cn({ isSelected, isFirst, isLast })}
    >
      <ProductCode>{product.code}</ProductCode>
      <ProductName>{product.name}</ProductName>
    </ProductItemWrapper>
  )
}

const ProductListScrollable = styled.div`
  overflow: auto;
  flex: 1;
  height: 100%;
`

const ProductItemWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
  border-bottom: 0.5px solid #f6f7f9;

  &.isFirst {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &.isLast {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:hover,
  &.isSelected {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.defaultHover};
  }
`

const ProductCode = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  width: 3rem;
  min-width: 3rem;
  font-weight: 500;
`

const ProductName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutralBlack};
`
