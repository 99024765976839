import { Alert, AnimatedContentLoader, Button, HeaderBlock, Prompt } from '@components'
import { UserRole } from '@constants'
import { PermissionBoundary, useBusinessContext, usePermissionBoundary } from '@context'
import { useQueryParam } from '@hooks'
import { deleteAccount, fetchAccount, hideAccount, IAccount } from '@query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaEyeSlash, FaPen, FaTrash } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { AccountFormModal } from './AccountFormModal'
import { AccountOverview } from './AccountOverview'

interface Props {
  accountId: number
}

export const AccountDetails: React.FC<Props> = ({ accountId }) => {
  const [showDelete, setShowDelete] = useState(false)
  const { businessId } = useBusinessContext()
  const [, setAccountId] = useQueryParam('id')
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const queryClient = useQueryClient()
  const [t] = useTranslation()

  const { data, isLoading } = useQuery<IAccount>([businessId, 'accounts', accountId], () =>
    fetchAccount({ businessId, accountId })
  )

  const deleteMutation = useMutation(() => deleteAccount({ businessId, accountId }), {
    onSuccess: async () => {
      setAccountId(undefined)
      setShowDelete(false)

      await queryClient.invalidateQueries([businessId, 'accounts'])
    },
    onError: () => {
      setShowDelete(false)
      toast.error(t('error.genericRetry'))
    }
  })

  const hideMutation = useMutation(() => hideAccount({ businessId, accountId }), {
    onSuccess: async () => {
      setAccountId(undefined)
      setShowDelete(false)

      await queryClient.invalidateQueries([businessId, 'accounts'])
    },
    onError: () => {
      setShowDelete(false)
      toast.error(t('error.genericRetry'))
    }
  })

  const renderAlert = () => {
    if (data?.is_used) {
      return (
        <Alert
          type="warning"
          title={t('accounts.delete.blockedTitleUsed')}
          description={t('accounts.delete.blockedDescriptionUsed')}
          isVisible={true}
        />
      )
    }

    return null
  }

  return (
    <>
      <StyledDetails key={accountId}>
        <AnimatedContentLoader isLoading={isLoading}>
          <DetailWrapper>
            {!isLoading && (
              <HeaderBlock
                header={`${data?.number} ${data?.name}`}
                subHeader={<></>}
                menuItems={
                  isEditor && [
                    {
                      name: t('accounts.hideAccount'),
                      Icon: FaEyeSlash,
                      onClick: () => hideMutation.mutateAsync()
                    },
                    {
                      name: t('accounts.deleteAccount'),
                      Icon: FaTrash,
                      onClick: () => setShowDelete(true),
                      intent: 'danger'
                    }
                  ]
                }
              />
            )}

            <ScrollableContent>{data && <AccountOverview account={data} />}</ScrollableContent>

            <PermissionBoundary requireRole={UserRole.EDITOR}>
              <StyledFooter>
                <AccountFormModal account={data}>
                  <Button icon={<FaPen />}>{t('accounts.editAccount')}</Button>
                </AccountFormModal>
              </StyledFooter>
            </PermissionBoundary>
          </DetailWrapper>
        </AnimatedContentLoader>
      </StyledDetails>

      <Prompt
        isVisible={showDelete}
        title={t('accounts.delete.title')}
        description={
          <>
            {renderAlert()}
            <div>{t('accounts.delete.description', { name: data?.name })}</div>
          </>
        }
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            intent: 'default',
            action: () => setShowDelete(false)
          },
          {
            text: t('accounts.deleteAccount'),
            icon: <FaTrash />,
            intent: 'danger',
            disabled: data?.is_used,
            action: async () => {
              await deleteMutation.mutateAsync()
            }
          }
        ]}
      />
    </>
  )
}

const StyledDetails = styled.div`
  height: 100%;
  width: 100%;
  background: white;
`

const DetailWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`

const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
  padding-top: 0;
`

const StyledFooter = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
`
