import React, { useEffect, useReducer, useRef, useState } from 'react'
import { AnimatedContentLoader, Button, Input, InputProps, ModalProps, ModalV2 } from '..'
import { PaginatedResponse } from '@root/query'
import { useQueries, useQuery, useQueryClient } from 'react-query'
import { createPortal } from 'react-dom'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { FaPlus } from 'react-icons/fa'

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { useDebouncedValue, useVirtualList } from '@root/hooks'
import { useTranslation } from 'react-i18next'

const MAX_HEIGHT_DROPDOWN = 300

export interface ItemRenderProps<T> {
  isFocused: boolean
  isSelected: boolean
  onKeyDown: (e: any) => void
  handleOnItemClick: (item: T) => void
  handleOnEditClick: (item: T) => void
  style: React.CSSProperties
  showEditItem: boolean
}

export interface SuggestionRenderProps<T> {
  handleOnItemClick: (item: T) => void
}

export interface FetchItemsProps {
  search?: string
  page: number
}

type RenderFunctionType = (onCreate: OnCreateType, item: any) => React.ReactNode
type OnCreateType = (data: any) => any

export interface UniversalAPISelectorProps<T> extends InputProps {
  queryKey: string
  dropdownHeaderIcon?: React.ReactElement
  dropDownHeader: string

  fetchItem: (id: number) => Promise<T>
  fetchItems: (props: FetchItemsProps) => Promise<PaginatedResponse<T>>

  renderRow: (item: T, props: ItemRenderProps<T>) => React.ReactNode
  renderUpdateForm?: RenderFunctionType
  renderCreateForm?: RenderFunctionType

  getDataToShow: (item: T) => string

  value?: number
  onChange: (itemId: number) => void

  modalHeaderNew?: string
  modalHeaderUpdate?: string
  formId: string

  noDataMessage: string

  createModalProps?: Partial<ModalProps>
  updateModalProps?: Partial<ModalProps>

  clearSelectedItem: () => void
  fetchSuggestion?: () => Promise<any>
  renderSuggestion?: (item: T, isEnabled, porps: SuggestionRenderProps<T>) => React.ReactNode
  isSuggestionEnabled?: boolean
}

const UniversalAPISelector: React.FC<UniversalAPISelectorProps<any>> = ({
  queryKey,
  fetchItem,
  fetchItems,
  renderRow,
  id,
  name,
  noDataMessage,
  getDataToShow,
  renderCreateForm,
  renderUpdateForm,
  onChange,
  value: itemId,
  modalHeaderNew,
  modalHeaderUpdate,
  dropdownHeaderIcon,
  dropDownHeader,
  formId,
  createModalProps,
  updateModalProps,
  clearSelectedItem,
  fetchSuggestion,
  renderSuggestion,
  isSuggestionEnabled: _isSuggestionEnabled,
  ...rest
}) => {
  interface State {
    searchTerm: string
    page: number
    isDropDownOpen: boolean
  }

  interface Action {
    type: 'search_changed' | 'set_page' | 'set_dropdown'
    searchTerm?: string
    page?: number
    isDropDownOpen?: boolean
  }

  function reducer(state: State, action: Action) {
    switch (action.type) {
      case 'search_changed': {
        return {
          searchTerm: action.searchTerm,
          page: 1,
          isDropDownOpen: action.isDropDownOpen ?? true
        }
      }
      case 'set_page': {
        return { ...state, page: action.page }
      }
      case 'set_dropdown': {
        return { ...state, isDropDownOpen: action.isDropDownOpen }
      }
      default:
        throw Error('Unknown action: ' + action.type)
    }
  }

  const [state, dispatch] = useReducer(reducer, { searchTerm: '', page: 1, isDropDownOpen: false })
  const [focusIndex, setFocusIndex] = useState(-1)
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [error, setError] = useState()

  const queryClient = useQueryClient()
  const debouncedSearchTerm = useDebouncedValue<string>(state.searchTerm || ' ', 300)
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
  const [t] = useTranslation()

  const isSuggestionEnabled = !!fetchSuggestion && !!renderSuggestion && _isSuggestionEnabled

  // When form is reset (value goes to null/undefined) need to also set the search term to empty
  useEffect(() => {
    if (!itemId) {
      dispatch({ type: 'search_changed', searchTerm: '', isDropDownOpen: false })
    }
  }, [itemId])

  const pageNumbers =
    debouncedSearchTerm.length === 0 ? [] : [...Array(state.page).keys()].map(idx => idx + 1)
  const results = useQueries(
    pageNumbers.map(page => ({
      queryKey: [
        `${queryKey}-items`,
        'items',
        { page, debouncedSearchTerm: debouncedSearchTerm.trim() }
      ],
      queryFn: () => fetchItems({ page, search: debouncedSearchTerm.trim() }),
      keepPreviousData: true,
      onError: error => {
        setError(error)
      }
    }))
  )

  const isItemsLoading = results.some(result => result.isLoading)
  const hasNextPage = !!results[results?.length - 1]?.data?.next
  const allItems =
    results?.reduce((results, result) => [...results, ...(result.data?.results ?? [])], []) ?? []

  const result = useQuery([`${queryKey}-item`, 'item', itemId], () => fetchItem(itemId), {
    enabled: itemId >= 0 && itemId !== null,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      dispatch({ type: 'search_changed', searchTerm: getDataToShow(data), isDropDownOpen: false })
    }
  })

  const { data: suggestion } = useQuery(
    [`${queryKey}-suggestion`, 'suggestion', itemId],
    () => fetchSuggestion?.(),
    {
      enabled: isSuggestionEnabled
    }
  )

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: state.isDropDownOpen,
    onOpenChange: open => {
      // When dropdown is closed, reset the search term to the selected item
      const item = allItems.find(item => item.id === itemId) ?? null
      dispatch({ type: 'search_changed', searchTerm: item ? getDataToShow(item) : '' })

      dispatch({ type: 'set_dropdown', isDropDownOpen: open })
    },
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset(10),
      flip(),
      size({
        apply: ({ rects, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${MAX_HEIGHT_DROPDOWN}px`
          })
        }
      })
    ]
  })

  useDismiss(context)

  const { renderItems, scrollRef, scrollToIndex } = useVirtualList({
    items: allItems,
    itemSize: 35,
    overshootCount: 10,
    onScrollBottom: () => {
      hasNextPage &&
        dispatch({ type: 'set_page', page: results[results?.length - 1]?.data?.next ?? 1 })
    }
  })

  const onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown': {
        if (focusIndex === allItems.length - 1) {
          setFocusIndex(0)
          scrollToIndex(0)
          break
        }
        setFocusIndex(Math.min(focusIndex + 1, allItems.length - 1))
        scrollToIndex(Math.min(focusIndex + 1, allItems.length - 1))
        break
      }
      case 'ArrowUp': {
        if (focusIndex === 0) {
          setFocusIndex(allItems.length - 1)
          scrollToIndex(allItems.length - 1)
          break
        }
        setFocusIndex(Math.max(focusIndex - 1, 0))
        scrollToIndex(Math.max(focusIndex - 1, 0))
        break
      }
      case 'Enter': {
        const result = allItems[focusIndex]
        if (result) {
          handleItemClick(result)
        }
        setFocusIndex(-1)
        dispatch({ type: 'set_dropdown', isDropDownOpen: false })
        e.stopPropagation()
        e.preventDefault()
        break
      }
      default:
        break
    }
  }

  const onCreate: OnCreateType = (data: any): any => {
    setCreateModalOpen(false)
    onChange(data?.id)
    dispatch({ type: 'search_changed', searchTerm: getDataToShow(data) })
    queryClient.invalidateQueries({ queryKey: [`${queryKey}-item`, 'item', itemId] })
  }

  const onUpdate: OnCreateType = (data: any): any => {
    setUpdateModalOpen(false)
    onChange(data?.id)
    dispatch({ type: 'search_changed', searchTerm: getDataToShow(data) })
    queryClient.invalidateQueries({ queryKey: [`${queryKey}-item`, 'item', itemId] })
  }

  const handleAddNewItemModalClose = () => {
    setCreateModalOpen(false)
  }
  const handleUpdateItemModalClose = () => {
    setUpdateModalOpen(false)
  }

  const handleOpenAddNewItemModal = () => {
    dispatch({ type: 'set_dropdown', isDropDownOpen: false })
    setCreateModalOpen(true)
  }

  const handleOnEditClick = item => {
    onChange(item?.id)
    dispatch({ type: 'set_dropdown', isDropDownOpen: false })
    setUpdateModalOpen(true)
  }

  const handleItemClick = item => {
    onChange(item?.id)
    dispatch({ type: 'search_changed', searchTerm: getDataToShow(item) })
    dispatch({ type: 'set_dropdown', isDropDownOpen: false })
  }

  const renderModal = (
    renderForm,
    handleOnClose,
    rest,
    isVisible: boolean,
    modalType: 'new' | 'update'
  ) => {
    return (
      <ModalV2
        header={modalType === 'new' ? modalHeaderNew : modalHeaderUpdate}
        isVisible={isVisible}
        handleOnClose={() => handleOnClose(false)}
        footer={
          <>
            <Button onClick={() => handleOnClose(false)}>{t('general.cancel')}</Button>
            <Button
              type="submit"
              intent="success"
              form={formId}
              data-test="universal-api-selector-submit-form"
            >
              {t('general.save')}
            </Button>
          </>
        }
        {...rest}
      >
        {renderForm}
      </ModalV2>
    )
  }

  return (
    <>
      {/* RENDER MODALS */}
      {renderCreateForm &&
        createModalProps &&
        renderModal(
          renderCreateForm(onCreate, undefined),
          handleAddNewItemModalClose,
          createModalProps,
          isCreateModalOpen,
          'new'
        )}
      {result?.data &&
        renderUpdateForm &&
        updateModalProps &&
        renderModal(
          renderUpdateForm(onUpdate, result.data),
          handleUpdateItemModalClose,
          updateModalProps,
          isUpdateModalOpen,
          'update'
        )}

      {/* USER INPUT */}
      <Input
        data-test="universal-api-selector-v2-input"
        ref={reference}
        onKeyDown={onKeyDown}
        value={state.searchTerm}
        onFocus={() => dispatch({ type: 'set_dropdown', isDropDownOpen: true })}
        autoComplete="off"
        clearSelection={() => {
          dispatch({
            type: 'search_changed',
            searchTerm: ''
          })
          clearSelectedItem()
        }}
        onChange={e => {
          dispatch({
            type: 'search_changed',
            searchTerm: e.currentTarget.value
          })
        }}
        errors={error}
        showClearInput={!!itemId}
        {...rest}
      ></Input>

      <input type="hidden" id={id} name={name} value={itemId} />

      {/* DROP DOWN */}
      {state.isDropDownOpen &&
        createPortal(
          <StyledDropdown
            ref={floating}
            style={{
              top: y || 0,
              left: x || 0,
              position: strategy
            }}
          >
            <FloatingFocusManager context={context}>
              <>
                <ActionBar>
                  {dropdownHeaderIcon && dropdownHeaderIcon}
                  <HeaderText>{dropDownHeader}</HeaderText>

                  {renderCreateForm && (
                    <Button
                      isSecondary={true}
                      icon={<FaPlus></FaPlus>}
                      onClick={() => handleOpenAddNewItemModal()}
                      data-test="universal-api-selector-v2-add-new"
                    ></Button>
                  )}
                </ActionBar>

                <StyledDropdownScrollable ref={scrollRef}>
                  <AnimatedContentLoader
                    isLoading={isItemsLoading && allItems.length === 0}
                    isEmpty={allItems?.length === 0}
                    isEmptyDescription={noDataMessage}
                  >
                    {renderItems((item, style, index) =>
                      renderRow(item, {
                        isFocused: focusIndex === index,
                        isSelected: itemId === item.id,
                        handleOnItemClick: item => handleItemClick(item),
                        handleOnEditClick: item => handleOnEditClick(item),
                        style: style,
                        onKeyDown: onKeyDown,
                        showEditItem: renderUpdateForm !== undefined ? true : false
                      })
                    )}
                  </AnimatedContentLoader>
                </StyledDropdownScrollable>
              </>
            </FloatingFocusManager>
          </StyledDropdown>,
          rootRef.current
        )}
      {isSuggestionEnabled &&
        suggestion &&
        renderSuggestion?.(suggestion, isSuggestionEnabled, {
          handleOnItemClick: suggestion => handleItemClick(suggestion)
        })}
    </>
  )
}

export default UniversalAPISelector

const StyledDropdownScrollable = styled.div`
  overflow: auto;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing.xxs}rem;
`

const StyledDropdown = styled(motion.div).attrs({
  initial: { y: 10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
})`
  display: flex;
  flex-direction: column;
  max-height: ${MAX_HEIGHT_DROPDOWN}px;
  background: white;
  z-index: 100000;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
`

const ActionBar = styled.div`
  display: flex;
  font-weight: 500;
  padding: 0rem ${({ theme }) => theme.spacing.sm}rem 0rem ${({ theme }) => theme.spacing.sm}rem;
  align-items: center;
  min-height: 40px;
`

const HeaderText = styled.div`
  flex: 1;
`
