import { AnimatedContentLoader, SideSheet, SingleColumnLayout } from '@components'
import { AccountType } from '@constants'
import { useBusinessContext } from '@context'
import { useAccountStruct, useQueryParam } from '@hooks'
import { fetchAccounts, IAccount, updateAccount } from '@query'
import React, { useState } from 'react'
import useVirtual from 'react-cool-virtual'
import { useTranslation } from 'react-i18next'
import { FaCaretRight } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { AccountActionBar, AccountDetails } from '../../components'
import { TabularAccountTable } from './TabularAccountTable'

const HEADER_HEIGHT = 70
const TABLE_ROW_HEIGHT = 45
const TABLE_HEADER_HEIGHT = 35

export interface IUpdateTabularAccountProps {
  accountId: number
  data: Partial<IAccount>
}

export const TabularAccountPage: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [filters, setFilter] = useState({})
  const [accountId, setAccountId] = useQueryParam<number>('id', value => parseInt(value, 10))

  const queryParams = { all: true, search, ...filters }
  const { data, isLoading } = useQuery([businessId, 'tabular_accounts', queryParams], () =>
    fetchAccounts({ businessId }, { page_size: 99999, ...queryParams })
  )
  const accountStruct = useAccountStruct(data?.results || [])

  const updateMutation = useMutation<unknown, unknown, IUpdateTabularAccountProps>(
    ({ accountId, data }) => {
      return updateAccount({ businessId, accountId }, data)
    },
    {
      onSuccess: (data: IAccount) => {
        queryClient.invalidateQueries([businessId, 'accounts'])
        queryClient.setQueriesData(
          [businessId, 'tabular_accounts'],
          ({ results: oldResults, ...rest }) => {
            const results = oldResults.map(account => (account.id === data.id ? data : account))
            return { results, ...rest }
          }
        )
      }
    }
  )
  const updateData = data => updateMutation.mutateAsync(data)

  const { outerRef, innerRef, items } = useVirtual<HTMLDivElement, HTMLDivElement>({
    itemCount: accountStruct.length,
    itemSize: index => {
      const header = accountStruct[index]
      const size = HEADER_HEIGHT + header.accounts.length * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT
      return size
    }
  })

  const filterOptions = React.useMemo(
    () => [
      {
        id: 'is_used',
        name: t('accounts.actionBar.filterIsUsed'),
        filter: { is_used: true }
      },
      {
        id: 'bank',
        name: t('accounts.actionBar.filterBank'),
        filter: { type: [AccountType.ASS_PAY] }
      },
      {
        id: 'revenue',
        name: t('accounts.actionBar.filterRev'),
        filter: { type: [AccountType.REV, AccountType.REV_SAL, AccountType.REV_NO] }
      },
      {
        id: 'expense',
        name: t('accounts.actionBar.filterExp'),
        filter: { type: [AccountType.EXP, AccountType.EXP_NO, AccountType.EXP_50] }
      },
      {
        id: 'taxes',
        name: t('accounts.actionBar.filterTax'),
        filter: {
          type: [AccountType.EXP_TAX, AccountType.LIA_VAT, AccountType.ASS_VAT]
        }
      }
    ],
    []
  )

  const renderRow = React.useCallback(({ index, accountStruct }) => {
    // Virtual list and react query combination has a side effect where
    // number of virtual items can temporarily differ from number of
    // accountStruct items. This works around that issue.
    if (!accountStruct[index]) return

    const { path, accounts } = accountStruct[index]
    return (
      <div key={path.join(' > ')}>
        <StyledHeaderWrapper>
          {path.map((p, i) => (
            <>
              <span key={i}>{p}</span>
              {i < path.length - 1 && (
                <span key={i + '-caret'}>
                  <FaCaretRight />
                </span>
              )}
            </>
          ))}
        </StyledHeaderWrapper>

        <TabularAccountTable
          accounts={accounts}
          rowHeight={TABLE_ROW_HEIGHT}
          headerHeight={TABLE_HEADER_HEIGHT}
          updateData={updateData}
        />
      </div>
    )
  }, [])

  return (
    <>
      <SingleColumnLayout header={t('accounts.title')}>
        <PageWrapper>
          <AccountActionBar
            onSearch={search => setSearch(search)}
            onFilter={filters => setFilter(filters)}
            filters={filterOptions}
          />

          <PageContent ref={outerRef}>
            <AnimatedContentLoader
              isLoading={isLoading}
              isEmpty={accountStruct.length === 0}
              isEmptyDescription={t('accounts.empty')}
            >
              <div ref={innerRef}>
                {items.map(({ index }) => renderRow({ index, accountStruct }))}
              </div>
            </AnimatedContentLoader>
          </PageContent>
        </PageWrapper>
      </SingleColumnLayout>
      <SideSheet isShown={Boolean(accountId)} handleClose={() => setAccountId(undefined)}>
        {accountId && <AccountDetails accountId={accountId} />}
      </SideSheet>
    </>
  )
}

const StyledHeaderWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  height: ${HEADER_HEIGHT}px;

  & > * {
    align-self: center;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
  }
`

const PageWrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PageContent = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  position: relative;
`
