type KeyValue = string | number | boolean

export interface DefaultQueryParams {
  search?: string
  page?: number
  page_size?: number
  [key: string]: KeyValue | Iterable<KeyValue>
}

export interface QueryFunction<T, I> {
  (urlParams: T, queryParams?: DefaultQueryParams): Promise<I>
}

export interface MutationFunction<T, I> {
  (urlParams: T, data?: Partial<I>): Promise<I>
}

export interface ActionFunction<T, I, R> {
  (urlParams: T, data: I): Promise<R>
}

export interface PaginatedResponse<T> {
  next: number | null
  prev: number | null
  count: number
  results: T[]
}

export interface UseInfiniteQueryResult<T> {
  pages: PaginatedResponse<T>
}

interface HandleReturnProps {
  <T>({ status, data }: { status: number; data?: T }): T
}

export const handleReturn: HandleReturnProps = ({ status, data }) => {
  if (status >= 200 && status < 300) {
    return data
  } else {
    throw { status, data }
  }
}
