import { Button, Input, VatSelect, ProductSelector } from '@components'
import { useBusinessContext } from '@context'
import { fetchProduct, IProduct } from '@query'
import { motion } from 'framer-motion'
import React, { useEffect, useMemo } from 'react'
import { Control, Controller, get, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaPlus, FaTimes } from 'react-icons/fa'
import styled from 'styled-components'
import { getVatOptions } from '@root/utils/getVatOptions'
import { DEFAULT_DATA_INVOICE_ROW, calculateInvoiceRowValues } from './InvoiceForm'
import { deepEqual, formatCurrency } from '@root/utils'
import dayjs from 'dayjs'

const PRECISION_TO_ROUND = 100000

interface Props {
  control: Control
  isAdvanced: boolean
}

export const InvoiceRows: React.FC<Props> = ({ control, isAdvanced }) => {
  const [t] = useTranslation()
  const { data: business } = useBusinessContext()
  const { watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rows',
    keyName: '__id'
  })
  const unvalidatedDate = watch('invoicing_date')
  const formDate = dayjs(unvalidatedDate).isValid() ? dayjs(unvalidatedDate) : undefined

  const vatOptions = useMemo(
    () =>
      getVatOptions(business?.country_config, formDate).map(options => ({
        value: options.rate,
        label: `${options.rate} %`
      })),
    [formDate?.toISOString()]
  )

  useEffect(() => {
    if (fields.length === 0) append({})
  }, [])

  return (
    <InvoiceRowsWrapper>
      {fields.map((field, index) => (
        <InvoiceRow
          key={field.__id}
          index={index}
          vatOptions={vatOptions}
          isAdvanced={isAdvanced}
          onRemove={() => remove(index)}
          removeDisabled={fields.length <= 1}
        />
      ))}

      <motion.div layout="position" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          data-test="invoice-add-row-button"
          type="button"
          icon={<FaPlus />}
          onClick={() => append(DEFAULT_DATA_INVOICE_ROW)}
        >
          {t('invoicing.form.rows.addNew')}
        </Button>
      </motion.div>
    </InvoiceRowsWrapper>
  )
}

interface InvoiceRowProps {
  index: number
  vatOptions: { value: number; label: string }[]
  isAdvanced: boolean
  onRemove: () => void
  removeDisabled: boolean
}

const InvoiceRow: React.FC<InvoiceRowProps> = ({
  index,
  isAdvanced,
  vatOptions,
  onRemove,
  removeDisabled
}) => {
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useFormContext()

  const currentUnit = watch(`rows.${index}.unit`)

  const setRowValues = (product: IProduct) => {
    setValue(`rows.${index}.unit`, product.unit)
    setValue(`rows.${index}.name`, product.name)
    setValue(
      `rows.${index}.amount`,
      Math.round(product.vat_exclusive_amount * PRECISION_TO_ROUND) / PRECISION_TO_ROUND
    )
    setValue(`rows.${index}.vat_code`, product.vat_code)
    setValue(`rows.${index}.vat_rate`, product.vat_rate)
  }

  return (
    <StyledInvoiceRow layout="position" $isAdvanced={isAdvanced}>
      <InvoiceRowContent>
        <Controller
          name={`rows.${index}.product`}
          control={control}
          rules={{ required: { value: !isAdvanced, message: t('validation.required') } }}
          render={({ field, fieldState: { error } }) => (
            <div style={{ flex: isAdvanced ? '1 0 49%' : '1 0 49%' }}>
              <ProductSelector
                {...field}
                ref={null}
                onChange={(productId: number) => {
                  field.onChange(productId)
                  fetchProduct({ businessId, productId }).then(setRowValues)
                }}
                clearSelectedItem={() => setValue(`rows.${index}.product`, undefined)}
                required={!isAdvanced}
                errors={error}
              />
            </div>
          )}
        />

        <Input
          data-test="invoice-rows-name-input"
          type={isAdvanced ? 'text' : 'hidden'}
          label={t('invoicing.form.rows.name')}
          errors={get(errors, `rows.${index}.name`)}
          required={true}
          style={{ flex: '1 0 49%' }}
          {...register(`rows.${index}.name`, {
            required: {
              value: true,
              message: t('validation.required')
            },
            minLength: { value: 2, message: t('validation.required') }
          })}
        />

        <Input
          data-test="invoice-row-unit-input"
          type={isAdvanced ? 'text' : 'hidden'}
          label={t('invoicing.form.rows.unit')}
          errors={get(errors, `rows.${index}.unit`)}
          required={true}
          style={{ flex: '1 0 5%' }}
          {...register(`rows.${index}.unit`, {
            required: { value: true, message: t('validation.required') }
          })}
        />

        <Input
          data-test="invoice-form-amount-input"
          type={isAdvanced ? 'number' : 'hidden'}
          label={t('invoicing.form.rows.amount') + ' € / ' + (currentUnit || '-')}
          errors={get(errors, `rows.${index}.amount`)}
          required={true}
          step=".00001"
          style={{ flex: '1 0 10%' }}
          {...register(`rows.${index}.amount`, {
            required: { value: true, message: t('validation.required') },
            valueAsNumber: true
          })}
        />

        <Input
          data-test="invoice-row-quantity-input"
          label={
            currentUnit
              ? t('invoicing.form.rows.quantityWithUnit', {
                  unit: currentUnit
                })
              : t('invoicing.form.rows.quantityWithoutUnit')
          }
          required={true}
          type="number"
          step=".01"
          style={{ flex: isAdvanced ? '1 0 10%' : '1 0 40%' }}
          errors={get(errors, `rows.${index}.product_count`)}
          {...register(`rows.${index}.product_count`, {
            valueAsNumber: true,
            required: { value: true, message: t('validation.required') }
          })}
        />

        {isAdvanced ? (
          <VatSelectWrapper style={{ flex: '1 0 15%' }}>
            <VatSelect
              id={`rows.${index}.vat_select`}
              control={control}
              include={'sales'}
              vatCodeName={`rows.${index}.vat_code`}
              vatRateName={`rows.${index}.vat_rate`}
              isInvalid={Boolean(get(errors, `rows.${index}.vat_rate`))}
              options={vatOptions}
            />
          </VatSelectWrapper>
        ) : (
          <>
            <input {...register(`rows.${index}.vat_code`)} type="hidden"></input>
            <input {...register(`rows.${index}.vat_rate`)} type="hidden"></input>
          </>
        )}

        {isAdvanced && (
          <fieldset disabled={true} style={{ flex: '1 0 12%', minWidth: '75px' }}>
            <Input
              readOnly={true}
              label={t('invoicing.form.rows.total')}
              value={formatCurrency(
                calculateInvoiceRowValues(
                  getValues()?.rows[index]?.vat_rate,
                  getValues()?.rows[index]?.amount,
                  getValues()?.rows[index]?.product_count
                ).tot || 0
              )}
            ></Input>
          </fieldset>
        )}

        <div style={{ flex: '1 0 100%' }}>
          <Input
            data-test="invoice-row-description-input"
            label={t('invoicing.form.rows.description')}
            errors={get(errors, `rows.${index}.description`)}
            style={{ flex: 2 }}
            {...register(`rows.${index}.description`)}
          />
        </div>
      </InvoiceRowContent>

      <Button
        data-test="invoice-row-delete-row-button"
        type="button"
        isSecondary={true}
        icon={<FaTimes />}
        onClick={() => {
          if (!removeDisabled) {
            onRemove()
          } else {
            setValue('rows', [DEFAULT_DATA_INVOICE_ROW])
          }
        }}
        disabled={removeDisabled && deepEqual(getValues('rows'), [DEFAULT_DATA_INVOICE_ROW])}
      />
    </StyledInvoiceRow>
  )
}

const InvoiceRowsWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.xl}rem;
  min-height: 350px;
`

const StyledInvoiceRow = styled(motion.div).attrs({ layout: 'position' })<{ $isAdvanced: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  margin-bottom: ${({ theme, $isAdvanced }) =>
    $isAdvanced ? `${theme.spacing.xxl}rem;` : `${theme.spacing.xl}rem;`};
`

const InvoiceRowContent = styled.div`
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs}rem;

  .input-wrapper {
    margin: 0;
  }
`

const VatSelectWrapper = styled.div`
  max-width: 120px;
`
