import { AnimatedContentLoader, ModalV2, UniversalActionBar } from '@components'
import { UserRole } from '@constants'
import { useBusinessContext, useFeature, usePermissionBoundary } from '@context'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { InvoiceForm } from './InvoiceForm'
import { useQuery } from 'react-query'
import { fetchBusiness } from '@root/query'

interface Props {
  onSearch: (value: string) => void
  onFilter: (value: string) => void
}

export const InvoicingActionBar: React.FC<Props> = ({ onSearch, onFilter }) => {
  const [showCreate, setShowCreate] = useState(false)
  const [t] = useTranslation()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const featureInvoicing = useFeature('feature_invoicing')
  const featureKravia = useFeature('feature_kravia')
  const { businessId } = useBusinessContext()
  const { data: business, isLoading } = useQuery([businessId], () => fetchBusiness({ businessId }))
  const isEmpty = Object.keys(business).length === 0

  return (
    <>
      <UniversalActionBar
        showSearch={true}
        onSearch={onSearch}
        searchPlaceholder={t('invoicing.actions.search')}
        buttons={
          (isEditor &&
            featureInvoicing && [
              {
                icon: <FaPlus />,
                onClick: () => setShowCreate(true),
                children: t('invoicing.actions.create'),
                'data-test': 'new-invoice-button'
              }
            ]) ||
          []
        }
        showFilter={true}
        filters={[
          {
            id: 'sent-invoices',
            name: t('invoicing.filters.sentInvoices'),
            filter: { only_sales: true }
          },
          {
            id: 'received-invoices',
            name: t('invoicing.filters.receivedInvoices'),
            filter: { only_purchases: true }
          },
          {
            id: 'is-open',
            name: t('invoicing.filters.isOpen'),
            filter: { is_open: true }
          },
          {
            id: 'is-over-due',
            name: t('invoicing.filters.isOverDue'),
            filter: { is_over_due: true }
          },
          ...(featureKravia
            ? [
                {
                  id: 'in-debt-collection',
                  name: t('invoicing.filters.inDebtCollection'),
                  filter: { in_debt_collection: true }
                }
              ]
            : [])
        ]}
        onFilter={onFilter}
      />

      <ModalV2
        isVisible={showCreate}
        header={t('invoicing.form.titleCreate')}
        handleOnClose={() => setShowCreate(false)}
        width={800}
        height={900}
      >
        <AnimatedContentLoader isLoading={isLoading} isEmpty={isEmpty}>
          {business && (
            <InvoiceForm
              onSubmit={() => setShowCreate(false)}
              defaultValues={{
                payment_condition_days: business?.invoicing_default_payment_condition_days,
                penalty_interest: business?.invoicing_default_penalty_interest
              }}
            />
          )}
        </AnimatedContentLoader>
      </ModalV2>
    </>
  )
}
