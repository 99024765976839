import {
  Button,
  Modal,
  ModalContainer,
  ModalDialog,
  ModalDismissButton,
  ModalOpenButton
} from '@components'
import { IAccount } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccountForm } from './AccountForm'
import { useQueryParam } from '@root/hooks'
interface Props {
  account?: IAccount
  children: React.ReactNode
}

export const AccountFormModal: React.FC<Props> = ({ account, children }) => {
  const [t] = useTranslation()
  const [, setActiveAccount] = useQueryParam('id')

  const onCreate = (data: IAccount) => {
    setActiveAccount(data.id.toString())
  }

  return (
    <Modal>
      {({ hideModal }) => (
        <>
          <ModalOpenButton>{children}</ModalOpenButton>

          <ModalDialog width={600}>
            <ModalContainer
              header={t('accounts.newAccountHeader')}
              onClose={() => hideModal()}
              footer={
                <>
                  <ModalDismissButton>
                    <Button>{t('general.cancel')}</Button>
                  </ModalDismissButton>
                  <Button type="submit" intent="success" form="account-form">
                    {t('general.save')}
                  </Button>
                </>
              }
            >
              <AccountForm account={account} onClose={hideModal} onCreate={onCreate} />
            </ModalContainer>
          </ModalDialog>
        </>
      )}
    </Modal>
  )
}
