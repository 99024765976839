import {
  Button,
  Input,
  PopupMenu,
  PopupMenuButton,
  PopupMenuContainer,
  Select,
  usePopupMenu
} from '@components'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaPen } from 'react-icons/fa'
import styled from 'styled-components'

interface IOption {
  label: string
  value: string
  color?: string
}

interface Props {
  value: any
  updateData: (any) => Promise<any>
  row: any
  name: string
  label?: string
  info?: string
  isNumber?: boolean
  formatter?: (value: any) => string | React.ReactNode
  options?: IOption[]
  isRequired?: boolean
  onlyVisuals?: boolean
}

export const EditableCell: React.FC<Props> = ({
  label,
  info,
  value,
  name,
  row: { original },
  updateData,
  isNumber = false,
  formatter,
  options,
  isRequired = true
}) => {
  const _updateData = value => updateData({ accountId: original.id, data: { [name]: value } })

  let displayValue = value
  if (options) {
    const selected = options.find(option => option.value === value)
    displayValue = selected?.label
  }

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      // Simulate mousedown to trigger useOnClickOutside
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('mousedown', true, true)
      e.currentTarget.dispatchEvent(clickEvent)

      // Open dropdown by click
      e.currentTarget.click()
    }
  }

  return (
    <PopupMenuContainer>
      <PopupMenuButton>
        <StyledCell tabIndex={0} onKeyUp={handleKeyUp}>
          <span>{formatter ? formatter(displayValue) : displayValue}</span>
          <FaPen />
        </StyledCell>
      </PopupMenuButton>

      <PopupMenu menuGrow="left">
        <PopupContent>
          <EditForm
            label={label}
            info={info}
            initialValue={value}
            onUpdate={_updateData}
            isNumber={isNumber}
            options={options}
            isRequired={isRequired}
          />
        </PopupContent>
      </PopupMenu>
    </PopupMenuContainer>
  )
}

interface EditFormProps {
  label: string
  info: string
  initialValue: any
  isNumber: boolean
  onUpdate: (any) => Promise<any>
  options?: IOption[]
  isRequired: boolean
}

const EditForm: React.FC<EditFormProps> = ({
  label,
  info,
  initialValue,
  options,
  isNumber,
  onUpdate,
  isRequired
}) => {
  const [t] = useTranslation()
  const { toggleVisibility } = usePopupMenu()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      edit: initialValue
    }
  })

  const onSubmit = handleSubmit(async data => {
    try {
      await onUpdate(data.edit)
      toggleVisibility(false)
    } catch {
      return null
    }
  })

  const RenderInput = React.useCallback(() => {
    if (options) {
      return (
        <Select
          label={label}
          info={info}
          autoFocus={true}
          {...register('edit', {
            required: { value: isRequired, message: t('validation.required') }
          })}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      )
    }

    return (
      <Input
        label={label}
        info={info}
        type={isNumber ? 'number' : 'text'}
        step={isNumber ? '.01' : null}
        autoFocus={true}
        {...register('edit', {
          required: { value: true, message: t('validation.required') },
          valueAsNumber: isNumber
        })}
        errors={errors?.edit}
      />
    )
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <RenderInput />

      <ButtonContainer>
        <Button
          icon={<FaCheck />}
          style={{ padding: 6 }}
          isSecondary={true}
          intent="success"
          isLoading={isSubmitting}
        >
          {t('general.save')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

const StyledCell = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.sm}rem;

  & > * {
    align-self: center;
  }

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    color: ${({ theme }) => theme.colors.iconGray};
    width: ${({ theme }) => theme.iconSize.xxs}rem;
    height: ${({ theme }) => theme.iconSize.xxs}rem;
    margin-left: ${({ theme }) => theme.spacing.xs}rem;
    opacity: 0;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.nocfoBlue};

    & > svg {
      opacity: 1;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PopupContent = styled.div`
  padding: 1rem;
`
