import { ModalContainer } from '@components'
import { FloatingPortal } from '@floating-ui/react'
import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ModalDialog as StyledModalDialog, ModalOverlay } from './ModalV2.styled'

export interface ModalProps {
  header?: string
  footer?: React.ReactNode
  children: React.ReactNode
  isVisible: boolean
  width?: number
  height?: number
  handleOnClose: () => void
  noContainer?: boolean
}

export const ModalV2: React.FC<ModalProps> = ({
  header,
  footer,
  isVisible,
  handleOnClose,
  width,
  height,
  children,
  noContainer = false
}) => {
  // NOTE: Mouse up triggers also onClick event. Accidental mouseUp event on modal backdrop
  // might occur when selecting text inside modal, for example. This logic makes sure that
  // onClick events have been started on the backdrop as well.
  const [, setMouseDown] = useState(false)

  useHotkeys('escape', () => handleOnClose())

  return (
    <FloatingPortal>
      <AnimatePresence mode="wait">
        {isVisible && (
          <ModalOverlay
            onMouseDown={() => setMouseDown(true)}
            onTouchStart={() => setMouseDown(true)}
            onClick={() =>
              setMouseDown(value => {
                value && handleOnClose()
                return false
              })
            }
          >
            <StyledModalDialog
              onMouseDown={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
              width={width}
              height={height}
            >
              {noContainer ? (
                children
              ) : (
                <ModalContainer header={header} footer={footer} onClose={() => handleOnClose()}>
                  {children}
                </ModalContainer>
              )}
            </StyledModalDialog>
          </ModalOverlay>
        )}
      </AnimatePresence>
    </FloatingPortal>
  )
}
