import imgSrc from '@assets/undraw/undraw_secure_login_pdn4.svg'
import { Alert, BasicPage, Button, Input } from '@components'
import { useQueryParam } from '@hooks'
import {
  ISignInData,
  ISignInOTPData,
  ISignInOTPResult,
  nocfoAccountSignIn,
  nocfoAccountSignInOTP
} from '@query'
import { network, setAPIErrors } from '@utils'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ILoginForm {
  email: string
  password: string
  otp: string
}

export const NocfoAccountSigninPage: React.FC = () => {
  const [isMobileAppLogin] = useQueryParam('app', value => !!value)
  const [mobileRedirectUrl] = useQueryParam('redirectUrl')
  const [showError, setShowError] = useState(false)
  const [promptOTP, setPromptOTP] = useState(false)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<ILoginForm>()

  const signInMutation = useMutation<unknown, unknown, ISignInData>(
    data => nocfoAccountSignIn(null, data),
    {
      onSuccess: () => {
        setShowError(false)
        setPromptOTP(true)
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
        }

        if (status === 403) {
          setShowError(true)
          reset({ email: '', password: '' })
        }
      }
    }
  )

  const signInOTPMutation = useMutation<unknown, unknown, ISignInOTPData>(
    data => nocfoAccountSignInOTP(null, data),
    {
      onSuccess: (data: ISignInOTPResult) => {
        // Login inherited from the mobile app
        if (isMobileAppLogin) {
          location.replace(`${mobileRedirectUrl}?token=${data.token}`)
        }

        // Login inherited from the web app
        else {
          network.setAccessToken(data.token)
          location.replace('/')
        }
      },
      onError: ({ status }) => {
        if (status === 403) {
          setShowError(true)
          setPromptOTP(false)
        }
      }
    }
  )

  const onSubmit = handleSubmit(async data => {
    if (promptOTP) {
      await signInOTPMutation.mutateAsync({
        email: data.email,
        otp: data.otp
      })
    } else {
      await signInMutation.mutateAsync({
        email: data.email,
        password: data.password
      })
    }
  })

  return (
    <BasicPage title={t('nocfoLogin.loginTitle')} imageUrl={imgSrc}>
      <form onSubmit={onSubmit}>
        <Alert
          type="warning"
          isVisible={showError}
          description={t('nocfoLogin.invalidCredentials')}
        />

        {!promptOTP && (
          <>
            <Input
              label={t('nocfoLogin.email')}
              errors={errors.email}
              {...register('email', {
                required: { value: true, message: t('validation.required') }
              })}
            />
            <Input
              label={t('nocfoLogin.password')}
              type="password"
              {...register('password', {
                required: { value: true, message: t('validation.required') }
              })}
              errors={errors.password}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                disable={signInMutation.isLoading}
                showSpinner={signInMutation.isLoading}
                intent="primary"
              >
                {t('nocfoLogin.loginButton')}
              </Button>
            </div>

            <br />
            <br />
            <StyledLink to="/nocfo-password-reset">{t('nocfoLogin.resetPasswordLink')}</StyledLink>
            {!isMobileAppLogin && (
              <StyledLink
                to={{
                  pathname: '/',
                  search: window.location.search
                }}
              >
                {t('nocfoLogin.otherOptionsLink')}
              </StyledLink>
            )}
          </>
        )}

        {promptOTP && (
          <>
            <label id="otp-label" htmlFor="otp-input">
              <b>{t('nocfoLogin.otpTitle')}</b>
            </label>
            <p>{t('nocfoLogin.otpDescription')}</p>

            <OTPInput
              id="otp-input"
              placeholder="______"
              {...register('otp', {
                required: { value: true, message: t('validation.required') }
              })}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                disable={signInOTPMutation.isLoading}
                showSpinner={signInOTPMutation.isLoading}
                intent="primary"
              >
                {t('nocfoLogin.loginButton')}
              </Button>
            </div>
          </>
        )}
      </form>
    </BasicPage>
  )
}

const OTPInput = styled.input`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xl}rem;
  letter-spacing: 1rem;
  text-align: center;
  padding: 1rem;
  padding-left: 2rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;

  outline: none;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  background: ${({ theme }) => theme.colors.neutralGray};

  &:focus {
    border: 3px solid ${({ theme }) => theme.colors.nocfoBlue};
  }
`

const StyledLink = styled(Link)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
`
