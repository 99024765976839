import { AccountSelectDropdown, Alert, Button, HeaderBlock, ToggleContainer } from '@components'
import { AccountType } from '@constants'
import { useBusinessContext } from '@context'
import { fetchBusiness, IBusiness, updateBusiness } from '@query'
import { setAPIErrors } from '@utils'
import { useAssistantAccounts } from '@utils/useAssistantAccounts'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

export const DefaultAccountSettings: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { allAccountOptions } = useAssistantAccounts()

  const getFormData = (data: Partial<IBusiness>): Partial<IBusiness> => {
    const {
      account_yle_tax_id,
      account_tax_deferrals_id,
      account_income_tax_rec_id,
      account_income_tax_lia_id,
      account_previous_profit_id,
      account_vat_liabilities_id
    } = data || {}

    return {
      account_yle_tax_id,
      account_tax_deferrals_id,
      account_income_tax_rec_id,
      account_income_tax_lia_id,
      account_previous_profit_id,
      account_vat_liabilities_id
    }
  }

  const { data } = useQuery([businessId], () => fetchBusiness({ businessId }), {
    refetchOnMount: false,
    onSuccess: data => {
      reset(getFormData(data))
    }
  })

  const updateMutation = useMutation<IBusiness, unknown, Partial<IBusiness>>(
    data => updateBusiness({ businessId }, data),
    {
      onSuccess: async data => {
        reset(getFormData(data))
        toast.success(t('general.changesSaved'))
      },
      onError: ({ status, data }) => {
        status === 400 && setAPIErrors(data, setError)
      }
    }
  )

  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { isDirty, isSubmitting }
  } = useForm({ defaultValues: getFormData(data) })

  const onSubmit = handleSubmit(data => updateMutation.mutateAsync(data))

  return (
    <StyledWrapper>
      <HeaderBlock header={t('settings.sections.defaultAccounts')} subHeader="" />

      <ContentWrapper>
        <Alert
          title={t('settings.accountsPage.info.title')}
          description={<ReactMarkdown>{t('settings.accountsPage.info.description')}</ReactMarkdown>}
        />

        <form onSubmit={onSubmit}>
          <ToggleContainer
            title={t('settings.accountsPage.sections.accounting')}
            openByDefault={true}
          >
            <Controller
              control={control}
              name="account_vat_liabilities_id"
              render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                <AccountSelectDropdown
                  inputRef={ref}
                  label={t('settings.accountsPage.account.vatLiabilities.name')}
                  accounts={allAccountOptions.filter(a => a.type === AccountType.LIA_VAT)}
                  value={value}
                  onSelect={result => onChange(result?.id)}
                  errors={error}
                  info={t('settings.accountsPage.account.vatLiabilities.info')}
                />
              )}
            />
          </ToggleContainer>

          <br />
          <ToggleContainer
            title={t('settings.accountsPage.sections.periodEnding')}
            openByDefault={true}
          >
            <Controller
              control={control}
              name="account_previous_profit_id"
              render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                <AccountSelectDropdown
                  inputRef={ref}
                  label={t('settings.accountsPage.account.previousProfit.name')}
                  accounts={allAccountOptions.filter(a => a.type === AccountType.LIA_PRE)}
                  value={value}
                  onSelect={result => onChange(result?.id)}
                  errors={error}
                  info={t('settings.accountsPage.account.previousProfit.info')}
                />
              )}
            />
          </ToggleContainer>
          <br />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" intent="success" disabled={!isDirty} isLoading={isSubmitting}>
              {t('general.save')}
            </Button>
          </div>
        </form>
      </ContentWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
`
