import { AccountSelector, AnimatedList, Input, TextTooltip, VatSelect } from '@components'
import {
  BlueprintType,
  ACC_TYPES_PURCHASE,
  ACC_TYPES_SALES,
  ACC_TYPES_SALES_PAYMENT,
  ACC_TYPES_PURCHASE_PAYMENT
} from '@constants'
import { useBusinessContext, useFeatureContext } from '@context'
import { DefaultQueryParams, fetchAccount } from '@query'
import { formatCurrency } from '@utils'
import cn from 'classnames'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaFeatherAlt, FaPlus, FaQuestionCircle } from 'react-icons/fa'
import {
  AnimatedWarningIconWrapper,
  DeleteIcon,
  EntryCol,
  EntryRow,
  EntryWrapper,
  Header,
  PaymentMethodWrapper,
  StyledButton,
  StyledTotalAmount
} from './DocumentEntrySection.styled'
import { useBlueprintAccountEffect } from './hooks'
import { getVatOptions, getVatRate } from '@utils/getVatOptions.tsx'
import dayjs from 'dayjs'
import { useValidatedValue } from '@hooks/useValidatedValue.ts'

interface BlueprintEntryProps {
  dateAt?: string
  baseName: string
  showDescriptions?: boolean
  accountFilter: DefaultQueryParams
  vatChoices: 'all' | 'purchase' | 'sales'
  blueprintType: BlueprintType
  accountLabel: string
  accountHint?: string
  buttonLabel: string
  buttonIcon: React.ReactNode
  hideFooter?: boolean
  minEntries?: number
  maxEntries?: number
  showTotalAmount?: boolean

  expectedPaymentAccount?: number
  expectedPaymentAccountAmount?: number
}

interface TotalAmountProps {
  baseName: string

  expectedPaymentAccount?: number
  expectedPaymentAccountAmount?: number
}

const parseError = (errors, fieldPath: string) => {
  let error = errors
  const parts = fieldPath.split('.')
  for (const part of parts) {
    error = error[part]
    if (!error) break
  }
  return error
}

const TotalAmount: React.FC<TotalAmountProps> = ({
  baseName,
  expectedPaymentAccount,
  expectedPaymentAccountAmount
}) => {
  const [t] = useTranslation()
  const { control } = useFormContext()

  const totalAmount = useWatch({ control, name: baseName })?.reduce(
    (current, { amount }) => (amount ? parseFloat(amount) : 0) + current,
    0
  )

  const formattedTotalAmount = formatCurrency(Math.round(totalAmount * 100) / 100)

  const calculatedPaymentAccountAmount = useBlueprintAccountEffect(expectedPaymentAccount)

  const showExpectedTotalAmountWarning =
    totalAmount !== 0 && expectedPaymentAccountAmount
      ? Math.round(expectedPaymentAccountAmount * 100) !==
        Math.round(calculatedPaymentAccountAmount * 100)
      : false

  return (
    <div>
      <StyledTotalAmount className={cn({ warning: showExpectedTotalAmountWarning })}>
        <AnimatePresence>
          {showExpectedTotalAmountWarning && (
            <AnimatedWarningIconWrapper>
              <TextTooltip
                tooltip={t('document.blueprint.totalDoesNotMatchExpected', {
                  expectedAmount: formatCurrency(expectedPaymentAccountAmount)
                })}
                placement="bottom-start"
              >
                <FaQuestionCircle className="warning-icon" />
              </TextTooltip>
            </AnimatedWarningIconWrapper>
          )}
        </AnimatePresence>
        <span className="title">{t('document.blueprint.total')}</span>
        <span className="amount">{formattedTotalAmount}</span>
      </StyledTotalAmount>
    </div>
  )
}

const BlueprintEntryEditor: React.FC<BlueprintEntryProps> = ({
  baseName,
  accountFilter,
  vatChoices,
  accountLabel,
  accountHint,
  buttonLabel,
  buttonIcon,
  minEntries = 1,
  maxEntries = 999,
  showTotalAmount = true,
  expectedPaymentAccount,
  expectedPaymentAccountAmount
}) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const {
    control,
    setFocus,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()
  const { data: business } = useBusinessContext()
  const { vat_obliged } = useFeatureContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: baseName,
    keyName: '__id',
    shouldUnregister: false,
    rules: { minLength: 1 }
  })

  const formDate = useValidatedValue<string, dayjs.Dayjs>({
    value: watch('date'),
    formatter: dateStr => dayjs(dateStr, 'YYYY-MM-DD'),
    validator: dateStr => dayjs(dateStr, 'YYYY-MM-DD').isValid()
  })

  const vatOptions = useMemo(
    () =>
      getVatOptions(business?.country_config, formDate).map(options => ({
        value: options.rate,
        label: `${options.rate} %`
      })),
    [formDate?.toISOString()]
  )

  useEffect(() => {
    let missingFields = minEntries - (fields?.length || 0)
    while (missingFields > 0) {
      append({}, { shouldFocus: false })
      missingFields--
    }
  }, [minEntries])

  const maxEntriesExceeded = fields.length >= maxEntries

  const deleteItem = (index: number) => {
    if (fields.length > minEntries) remove(index)
  }

  return (
    <AnimatedList
      items={fields.map((entry, index) => (
        <EntryWrapper key={entry.__id}>
          <EntryCol style={{ flex: 1 }}>
            <EntryRow>
              <div className="account-select-wrapper">
                <Controller
                  control={control}
                  name={`${baseName}.${index}.account_id`}
                  rules={{ required: { value: true, message: t('validation.required') } }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <AccountSelector
                        {...field}
                        ref={null}
                        clearSelectedItem={() =>
                          setValue(`${baseName}.${index}.account_id`, undefined)
                        }
                        required={true}
                        errors={error}
                        accountFilter={accountFilter}
                        label={accountLabel}
                        info={accountHint}
                        queryKey={`blueprint-purchase-${baseName}.${index}.account_id`}
                        id={`${baseName}.${index}.account_id`}
                        onChange={accountId => {
                          field.onChange(accountId)
                          if (vat_obliged) {
                            const commonOpts = {
                              shouldDirty: true,
                              shouldTouch: true
                            }
                            fetchAccount({ businessId, accountId: accountId }).then(result => {
                              setValue(
                                `${baseName}.${index}.vat_code`,
                                result?.default_vat_code,
                                commonOpts
                              )
                              const vatRate = getVatRate(
                                business.country_config,
                                result?.default_vat_rate_label,
                                formDate
                              )
                              setValue(`${baseName}.${index}.vat_rate`, vatRate, commonOpts)
                            })
                          }
                        }}
                      ></AccountSelector>
                    </>
                  )}
                />
              </div>

              {vat_obliged && business && (
                <div className="vat-select-wrapper">
                  <VatSelect
                    id={`${baseName}.${index}.vat_select`}
                    control={control}
                    include={vatChoices}
                    vatCodeName={`${baseName}.${index}.vat_code`}
                    vatRateName={`${baseName}.${index}.vat_rate`}
                    isInvalid={Boolean(parseError(errors, `${baseName}.${index}.vat_rate`))}
                    options={vatOptions}
                  />
                </div>
              )}

              <div className="amount-wrapper">
                <Controller
                  control={control}
                  name={`${baseName}.${index}.amount`}
                  rules={{
                    min: { value: 0, message: '< 0' },
                    max: { value: 9999999999, message: '> 9999999999' },
                    required: { value: true, message: t('validation.requiredShort') }
                  }}
                  defaultValue={0}
                  render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                    <Input
                      data-test="document-form-amount"
                      id={`${baseName}.${index}.amount`}
                      ref={ref}
                      type="number"
                      label={t('document.blueprint.amount')}
                      step="0.01"
                      errors={error}
                      value={value}
                      onChange={onChange}
                      required={true}
                    />
                  )}
                />
              </div>
            </EntryRow>
          </EntryCol>
          <EntryCol>
            <EntryRow>
              <DeleteIcon
                disabled={fields.length <= minEntries}
                onClick={() => deleteItem(index)}
              />
            </EntryRow>
          </EntryCol>
        </EntryWrapper>
      ))}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          {!maxEntriesExceeded && (
            <StyledButton
              type="button"
              data-test="add-sales-expense-button"
              icon={buttonIcon}
              onClick={async () => {
                append({}, { shouldFocus: false })
                setFocus(`${baseName}.${fields.length}.account_id`)
              }}
            >
              {buttonLabel}
            </StyledButton>
          )}
          {showTotalAmount && (
            <TotalAmount
              baseName={baseName}
              expectedPaymentAccount={expectedPaymentAccount}
              expectedPaymentAccountAmount={expectedPaymentAccountAmount}
            />
          )}
        </div>
      }
    />
  )
}

interface BlueprintEditorProps {
  expectedPaymentAccount?: number
  expectedPaymentAccountAmount?: number
}

export const BlueprintEditor: React.FC<BlueprintEditorProps> = ({
  expectedPaymentAccount,
  expectedPaymentAccountAmount
}) => {
  const [t] = useTranslation()
  const { control, watch, setValue } = useFormContext()

  const blueprintType = watch('blueprint_type')

  switch (blueprintType) {
    case BlueprintType.PURCHASE: {
      return (
        <div key={BlueprintType.PURCHASE}>
          <PaymentMethodWrapper>
            <Controller
              control={control}
              name={'blueprint.credit_account_id'}
              rules={{ required: { value: true, message: t('validation.required') } }}
              render={({ field, fieldState: { error } }) => (
                <AccountSelector
                  {...field}
                  ref={null}
                  clearSelectedItem={() => setValue('blueprint.credit_account_id', undefined)}
                  required={true}
                  errors={error}
                  accountFilter={{ type: ACC_TYPES_PURCHASE_PAYMENT }}
                  label={t('document.blueprint.paymentMethodAccount')}
                  info={t('document.blueprint.paymentMethodAccountInfoPurchase')}
                  queryKey="blueprint-purchase"
                ></AccountSelector>
              )}
            />
          </PaymentMethodWrapper>

          <Header>{t('document.blueprint.purchases')}</Header>
          <BlueprintEntryEditor
            blueprintType={blueprintType}
            baseName={'blueprint.debet_entries'}
            accountFilter={{ type: ACC_TYPES_PURCHASE }}
            vatChoices="purchase"
            accountLabel={t('document.blueprint.account')}
            buttonLabel={t('document.blueprint.addEntry')}
            buttonIcon={<FaPlus />}
            expectedPaymentAccount={expectedPaymentAccount}
            expectedPaymentAccountAmount={expectedPaymentAccountAmount}
          />
        </div>
      )
    }
    case BlueprintType.SALES: {
      return (
        <div key={BlueprintType.SALES}>
          <PaymentMethodWrapper>
            <Controller
              control={control}
              name={`blueprint.debet_account_id`}
              rules={{ required: { value: true, message: t('validation.required') } }}
              render={({ field, fieldState: { error } }) => (
                <AccountSelector
                  {...field}
                  ref={null}
                  clearSelectedItem={() => setValue('blueprint.debet_account_id', undefined)}
                  required={true}
                  errors={error}
                  accountFilter={{ type: ACC_TYPES_SALES_PAYMENT }}
                  label={t('document.blueprint.paymentMethodAccount')}
                  info={t('document.blueprint.paymentMethodAccountInfoSales')}
                  queryKey="blueprint-sales"
                ></AccountSelector>
              )}
            />

            <BlueprintEntryEditor
              blueprintType={blueprintType}
              baseName={'blueprint.expense_entries'}
              accountFilter={{ type: ACC_TYPES_PURCHASE }}
              vatChoices="purchase"
              accountLabel={t('document.blueprint.expenseAccount')}
              accountHint={t('document.blueprint.expenseAccountHint')}
              buttonLabel={t('document.blueprint.addExpense')}
              buttonIcon={<FaFeatherAlt />}
              minEntries={0}
              maxEntries={1}
              showTotalAmount={false}
            />
          </PaymentMethodWrapper>

          <Header>{t('document.blueprint.sales')}</Header>
          <BlueprintEntryEditor
            blueprintType={blueprintType}
            baseName={'blueprint.credit_entries'}
            accountFilter={{ type: ACC_TYPES_SALES }}
            vatChoices="sales"
            accountLabel={t('document.blueprint.account')}
            buttonLabel={t('document.blueprint.addEntry')}
            buttonIcon={<FaPlus />}
            expectedPaymentAccount={expectedPaymentAccount}
            expectedPaymentAccountAmount={expectedPaymentAccountAmount}
          />
        </div>
      )
    }
    default: {
      return (
        <div key={BlueprintType.MANUAL}>
          <Header>{t('document.blueprint.debet')}</Header>
          <BlueprintEntryEditor
            blueprintType={blueprintType}
            baseName={'blueprint.debet_entries'}
            accountFilter={null}
            vatChoices="all"
            accountLabel={t('document.blueprint.account')}
            buttonLabel={t('document.blueprint.addEntry')}
            buttonIcon={<FaPlus />}
            expectedPaymentAccount={expectedPaymentAccount}
            expectedPaymentAccountAmount={expectedPaymentAccountAmount}
          />
          <Header>{t('document.blueprint.credit')}</Header>
          <BlueprintEntryEditor
            blueprintType={blueprintType}
            baseName={'blueprint.credit_entries'}
            accountFilter={null}
            vatChoices="all"
            accountLabel={t('document.blueprint.account')}
            buttonLabel={t('document.blueprint.addEntry')}
            buttonIcon={<FaPlus />}
            expectedPaymentAccount={expectedPaymentAccount}
            expectedPaymentAccountAmount={expectedPaymentAccountAmount}
          />
        </div>
      )
      break
    }
  }
}
